<template>
  <div>
    <festival-tabs-component></festival-tabs-component>
    <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
    >
    <v-form>
      <festival-about-form-component ref="aboutForm"></festival-about-form-component>

      <div class="sticky-actions">
        <v-divider></v-divider>

        <v-row class="pa-3">
          <v-col cols="12" class="d-flex flex-column flex-md-row">
            <v-btn color="primary" class="mb-3 mb-md-0 mr-0 mr-md-3" :disabled="invalid || form.loading" @click="submit"
                   :loading="form.loading">
              Сохранить и продолжить
            </v-btn>
            <v-btn color="primary"
                   outlined
                   :disabled="form.loading"
                   @click="cancel()"
                   text>Отменить
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    </ValidationObserver>

    <leave-form :title="'Внесенные изменения в фестиваль не сохранены.'" ref="leaveModal"
                @goto="dontSaveAndGoTo"></leave-form>
  </div>


</template>
<script>
import {mapState, mapActions} from 'vuex';
import { ValidationObserver, setInteractionMode } from 'vee-validate/dist/vee-validate.full.esm';
setInteractionMode('eager');

import FestivalTabsComponent from '@/views/Festival/components/festival-tabs-component';
import FestivalAboutFormComponent from '@/views/Festival/components/forms/festival-about-form-component';
import {api} from '@/api';
import LeaveForm from '@/components/modals/leave-form';

export default {
  name: 'FestivalCreate',
  components: {LeaveForm, FestivalAboutFormComponent, FestivalTabsComponent, ValidationObserver},
  data() {
    return {
      form: {
        loading: false
      },
      formState: null,
      dialog: false,
      goTo: null,
      dontSaveAndGo: false,
    }
  },
  mounted() {
    this.setFormState();
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),
    async submit() {
      try {
        this.form.loading = true;
        let data = {
          ...this.$refs.aboutForm.form
        }
        let response = await api.festival.store(data)
        this.setFormState();
        await this.$router.push({name: 'festivals.edit', params: {id: response.data.id}})

      } catch (err) {
        this.error(err.response.data.error)
      }

      this.form.loading = false
    },
    setFormState() {
      this.formState = JSON.stringify({
        ...this.$refs.aboutForm.form
      })
    },
    dontSaveAndGoTo() {
      this.dontSaveAndGo = true;
      this.$router.push(this.goTo)
    },
    cancel() {
      this.$router.push({name: 'festivals.index'})
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.dontSaveAndGo) {

      let currentState = JSON.stringify({
        ...this.$refs.aboutForm.form
      });

      if (currentState !== this.formState) {
        this.$refs.leaveModal.dialog = true
        this.goTo = to
        return;
      }
    }
    next();
  },
}
</script>

<style scoped>

</style>
