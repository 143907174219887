import Vue from 'vue';
import Vuex from 'vuex';
import app from '@/store/app';
import auth from '@/store/auth';
import roles from '@/store/roles';
import svod from '@/store/svod';
import festival from '@/store/festival'

Vue.use(Vuex);

const namespaced = true;
export default new Vuex.Store({
    namespaced,
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        auth,
        roles,
        svod,
        festival
    }
});
