export const STATUS_INVOLVED = 'involved'
export const STATUS_DISQUALIFIED = 'disqualified'
export const STATUS_OUT_OF_RACE = 'out_of_race'

/**
 * Статус участника в таблице протоколов фестиваля (в режиме редактирования).
 *
 * @type {string[]}
 */
export const FESTIVAL_PROTOCOL_MEMBER_STATUSES = {
  [STATUS_INVOLVED]: 'Участвует',
  [STATUS_DISQUALIFIED]: 'Дисквал',
  [STATUS_OUT_OF_RACE]: 'Сошел',
}

/**
 * Интервал обновления статусов фестивалей (мс).
 *
 * @type {number}
 */
export const FESTIVAL_STATUS_UPDATE_INTERVAL = 15_000
