<template>
  <div>
    <FestivalTabsComponent :festival="festival" />

    <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
    >
      <div v-if="page.loading" class="text-center pa-10">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>

    <v-form v-else>
      <v-row class="pa-3">
        <v-col cols="8" class="d-flex flex-column flex-md-row">
          <v-btn
            color="primary"
            text
            outlined
            class="mr-4"
            @click="showTemplatesSidebar"
          >
            ИСПОЛЬЗОВАТЬ ШАБЛОН
          </v-btn>

          <v-btn
            color="primary"
            :disabled="invalid"
            text
            outlined
            @click="dialog.show = true"
          >
            СОХРАНИТЬ КАК ШАБЛОН
          </v-btn>
        </v-col>
      </v-row>

      <div class="pa-3">
        <v-checkbox
          label="Эстафета"
          class="mt-0"
          hide-details
          v-model="festival.has_relay"
        />
      </div>

      <templates-exercises-component
          :exercises="form.exercises"
          :exercises_list="exercises_list"
          :genders_list="genders_list"
          :age_categories_list="age_categories_list"
          :is_festival="true"
          :form_loading="form.loading"
          ref="templateExercises"
      >
      </templates-exercises-component>


      <div class="sticky-actions">
        <v-divider></v-divider>
        <v-row class="pa-3">
          <v-col cols="12">
            <v-btn color="primary"
                   :disabled="invalid || form.loading || ($refs.templateExercises && $refs.templateExercises.hasDuplicates)"
                   @click="submit" :loading="form.loading">
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    </ValidationObserver>


    <v-dialog
        v-model="dialog.show"
        persistent
        max-width="480"
    >
      <v-card>
        <v-card-title class="headline">
          Сохранить как новый шаблон
        </v-card-title>
        <v-card-text class="pb-0">Этот набор испытаний с заданными параметрами будет сохранен как новый шаблон</v-card-text>
        <v-form>
          <ValidationObserver
              ref="dialogObserver"
              v-slot="{ invalid }"
          >

            <v-card-text>
            <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                  >
                  <v-text-field
                      filled
                      hide-details
                      dense
                      label="Название шаблона"
                      :error-messages="errors"
                      v-model="dialog.form.name"
                      :disabled="dialog.form.loading"
                  >
                  </v-text-field>
                  </ValidationProvider>
            </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              :disabled="invalid || dialog.form.loading "
              :loading="dialog.form.loading"
              @click="saveAsTemplate()"
          >
            Да, сохранить
          </v-btn>
          <v-btn
              color="primary"
              text
              outlined
              @click="dialog.show = false"
              :disabled="dialog.form.loading"
          >
            Нет, отменить
          </v-btn>
        </v-card-actions>
          </ValidationObserver>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import { ValidationObserver, setInteractionMode, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
setInteractionMode('eager');

import FestivalTabsComponent from '@/views/Festival/components/festival-tabs-component';
import {api} from '@/api';
import TemplatesExercisesComponent from '@/views/Template/components/templates-exercises-component';

export default {
  name: 'FestivalExercises',
  components: {TemplatesExercisesComponent, FestivalTabsComponent, ValidationObserver, ValidationProvider},
  data() {
    return {
      festival: null,
      exercises_list: [],
      age_categories_list: [],
      available_svod_exercises: [],
      genders_list: [],
      form: {
        loading: false,
        exercises: [],
      },
      page: {
        loading: true
      },
      dialog: {
        show: false,
        form: {
          loading: false,
          name: null
        }
      }
    }
  },
  mounted() {
    this.fetch()

    this.$root.$on('setup-exercises', (exercises) => {
      this.form.exercises = exercises;
      this.notify('Шаблон применен')
    })
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('festival', ['set_festival']),
    ...mapActions('app', ['notify']),
    async fetch() {
      try {
        let response = await api.festival.exercises.all(this.$route.params.id);
        this.festival = response.data.item
        this.set_festival(this.festival)
        this.exercises_list = response.data.exercises.list;
        this.age_categories_list = response.data.age_categories.list;
        this.genders_list = response.data.genders.list;
        this.form.exercises = response.data.item.exercises;
        this.page.loading = false
      } catch (e) {
        console.error(e);
      }
    },
    async submit(){
      try{
        this.form.loading = true;
        const exercises = JSON.parse(JSON.stringify(this.$refs.templateExercises.table.list.map((el) => ({
          id: el.id,
          exercise_id: el.exercise_id,
          gender_ids: el.gender_ids,
          age_category_ids: el.age_category_ids,
          priority: el.priority
        }))))

        await api.festival.exercises.update(this.festival.id, {
          exercises: exercises,
        })

        await api.festival.update(this.festival.id, {
          ...this.festival,
          languages: this.festival.languages.map((language) => language.short_name),
        })

        await this.fetch();
        this.notify('Испытания сохранены')
        this.form.loading = false;
      } catch (e) {
        console.error(e);
      }
    },
    async saveAsTemplate(){
      try {
        this.dialog.form.loading = true;
        await api.template.store({ name: this.dialog.form.name, exercises: this.$refs.templateExercises.table.list })
        this.dialog.form.name = null;
        this.dialog.show = false;
        this.dialog.form.loading = false;
        this.notify('Шаблон сохранен')
      } catch (e) {
        console.error(e)
      }
    },
    showTemplatesSidebar(){
      this.$root.$emit('show-templates-sidebar');
    },
  },
}
</script>
