<template>
  <div>
    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" class="mb-6">
        <v-btn v-if="svod" class="primary" link :href="svod.errors_log_file_path" >Скачать файл логов</v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>
<script>


import {api} from '@/api'
import {mapState, mapActions} from 'vuex';

export default {
  name: 'SvodLogs',
  data() {
    return {
      table: {
        headers:  [
          { text: '№ строки', value: 'line' },
          { text: 'Название столбца', value: 'column', sortable: false },
          { text: 'Значение', value: 'value', sortable: false },
          { text: 'Тип ошибки', value: 'message', sortable: false },
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      form: {
        loading: false
      },
      svod: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify']),
    async fetch(){
      try {
        this.form.loading = true;
        let response = await api.svod.logs({
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });
        this.table.list = response.data.list
        this.table.meta = response.data.meta
        this.svod = response.data.item
        this.form.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    optionsUpdated(){
      this.fetch()
    },
  },
}
</script>

<style scoped>

</style>
