<template>
  <v-card>
    <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
    >
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <p class="font-weight-medium mb-0">Новый СВОД загружен</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <ValidationProvider
              name="Название"
              rules="required"
              v-slot="{ errors }"
              mode="eager"
          >

            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >  <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="form.start_date"
                  label="Начало действия"
                  prepend-icon="mdi-calendar"
                  filled
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details
                  :disabled="form.loading"
              ></v-text-field>
            </template>
              <v-date-picker
                  v-model="form.start_date"
                  :error-messages="errors"
                  :disabled="form.loading"
                  :min="tomorrow"
                  :allowed-dates="allowedDates"
              ></v-date-picker>
            </v-menu>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-card-text>
  <v-card-actions>
    <v-btn
        color="primary"
        @click="submit()"
        :disabled="invalid || form.loading"
        :loading="form.loading"
    >
      Применить
    </v-btn>
  </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';
setInteractionMode('eager');
import {api} from '@/api';
import {mapState} from 'vuex';

export default {
name: 'svod-complete-form-component',
  components: {ValidationObserver, ValidationProvider},
  data: () => ({
    form: {
      loading: false,
      start_date: null,
    },
  }),
  computed: {
    ...mapState('svod', ['activeSvod', 'disabled_dates']),
    tomorrow(){
      const tomorrow = new Date();
      return new Date(tomorrow.setDate(tomorrow.getDate() + 1)).toISOString().slice(0, 10);
    }
  },
  methods: {
    async submit(){
      try {
        this.form.loading = true
        await api.svod.update(this.activeSvod.id, this.form)
        this.$emit('updated');
      } catch (e) {
        console.log(e)
      }
    },
    allowedDates(val){
      return !this.disabled_dates.includes(val)
    }
  }
}
</script>

<style scoped>

</style>