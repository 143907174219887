import { guard } from '@/guards/guard';
import Mobile from '@/views/Mobile/Mobile';

export default {
  path: '/mobile',
  name: 'mobile.index',
  component: Mobile,
  meta: {
    title: 'МП для организаторов'
  },
  beforeEnter: guard,
  children: [
    {
      path: 'app',
      name: 'mobile.app',
      beforeEnter(to, from, next) {
        guard(to, from, next);

        location.href = process.env.VUE_APP_MOBILE_APP_URL;
      }
    },
    {
      path: 'apk',
      name: 'mobile.apk',
      beforeEnter(to, from, next) {
        guard(to, from, next);
        location.href = process.env.VUE_APP_APK_DOWNLOAD_URL;
      }
    }
  ]
};
