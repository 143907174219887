<template>
  <div>
    <v-btn
        color="primary"
        v-permission="'users.create'"
        @click="uploader.show = !uploader.show"
        class="mb-3"
        :disabled="(showUploader || showProgressBar || isSvodProcessed)"
    >
      Загрузить свод
    </v-btn>

    <svod-uploader-component v-if="showUploader" @uploaded="uploaded" @close="uploader.show = false"></svod-uploader-component>
    <svod-progress-component v-if="showProgressBar" @reset="resetForm" @canceled="canceled"></svod-progress-component>
    <svod-complete-form-component v-if="isSvodProcessed" @updated="svodListFetch()"></svod-complete-form-component>
  </div>
</template>

<script>
import SvodUploaderComponent from '@/views/Svod/components/svod-uploader-component';
import SvodProgressComponent from '@/views/Svod/components/svod-progress-component';
import SvodCompleteFormComponent from '@/views/Svod/components/svod-complete-form-component';
import {mapGetters, mapState, mapActions} from 'vuex';

export default {
  name: 'svod-upload-form-component',
  components: {SvodCompleteFormComponent, SvodProgressComponent, SvodUploaderComponent},
  data: () => ({
    uploader: {
      show: false
    }
  }),
  computed: {
    ...mapState('svod', ['activeSvod']),
    ...mapGetters('svod', ['isSvodProcessing', 'isSvodProcessed', 'isSvodFailed', 'showProgressBar']),
    showUploader(){
      return (this.uploader.show && !this.isSvodProcessing && !this.isSvodProcessed && !this.isSvodFailed);
    },
  },
  created() {
    this.getActiveSvod();
  },
  methods: {
    ...mapActions('svod', ['resetActiveSvod', 'getActiveSvod']),
    resetForm(){
      this.resetActiveSvod();
      this.uploader.show = true;
    },
    uploaded(){
      this.getActiveSvod();
    },
    canceled(){
      this.getActiveSvod();
    },
    svodListFetch(){
      this.getActiveSvod();
      this.$parent.fetch();
    }
  }
}
</script>

<style scoped>

</style>