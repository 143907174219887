<template>
  <v-tooltip top content-class="tooltip-gto">
    <template #activator="{ attrs, on }">
      <span v-bind="attrs" v-on="on">
        <Component :is="getLabel.icon" />
      </span>
    </template>
    {{ getLabel.text }}
  </v-tooltip>
</template>

<script>
import PendingIcon from '@/views/Festival/icons/PendingIcon.vue'
import ProcessingIcon from '@/views/Festival/icons/ProcessingIcon.vue'
import SuccessIcon from '@/views/Festival/icons/SuccessIcon.vue'
import FailedIcon from '@/views/Festival/icons/FailedIcon.vue'

export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    hasLabel() {
      return this.labels?.[this.status]
    },
    getLabel() {
      if (this.hasLabel) {
        return this.labels[this.status]
      }

      return {
        text: 'Статус не найден',
        icon: PendingIcon,
      }
    },
    labels() {
      return {
        pending: {
          text: 'Нет сформированных протоколов',
          icon: PendingIcon,
        },
        processing: {
          text: 'Протоколы в процессе формирования',
          icon: ProcessingIcon,
        },
        completed: {
          text: 'Генерация протоколов завершена успешно',
          icon: SuccessIcon,
        },
        failed: {
          text: 'Протоколы сформированы с ошибкой',
          icon: FailedIcon,
        },
      }
    },
  }
}
</script>
