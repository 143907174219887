<template>
  <div>
    <festival-tabs-component :festival="festival"></festival-tabs-component>

      <div v-if="page.loading" class="text-center pa-10">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>

    <div v-show="!page.loading">
      <v-row class="pa-3 pb-0">
        <v-col cols="12" lg="6" class="d-flex flex-column flex-lg-row">
          <v-btn
            class="mb-3 mb-lg-0 mr-0 mr-lg-3"
            color="primary"
            outlined
            text
            @click="addJudges()"
          >
            Добавить судей
          </v-btn>
          <v-btn
            color="primary"
            outlined
            text
            :disabled="canRemoveJudges"
            @click="$refs.remover.dialog = true"
          >
            Удалить
          </v-btn>
        </v-col>
        <v-col>
          <v-text-field
              color="primary"
              v-model="form.search"
              filled
              search
              placeholder="Поиск судьи"
              dense
              hide-details
              @keyup="search"
          >
            <v-icon
                slot="prepend-inner"
            >
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
          v-model="selected"
          :headers="table.headers"
          :items="table.list"
          item-key="id"
          class="table"
          :loading="table.loading"
          :server-items-length="table.meta.total"
          ref="table"
          :single-select="false"
          show-select
          :options.sync="table.options"
          :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
          @update:options="optionsUpdated"
      >
        <template #item.profile="{item}">
          {{ item.user.profile.id }}
        </template>
        <template #item.fullname="{item}">
          {{ item.user.profile.fullname }}
        </template>
        <template #item.email="{item}">
          {{ item.user.email }}
        </template>
        <template #item.actions="{item}">
          <v-tooltip left content-class="tooltip-gto">
            <template #activator="{on, attrs}">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            Удалить
          </v-tooltip>
        </template>
      </v-data-table>

    </div>

    <create-judges-component ref="judgesFormComponent" @submitted="judgesAdded()"></create-judges-component>
    <remove-item ref="remover" :populate-with="selectedJudges" :type="'judge'" @remove-item-success="removed"></remove-item>
  </div>
</template>
<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import {setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';
setInteractionMode('eager');

import FestivalTabsComponent from '@/views/Festival/components/festival-tabs-component';
import {api} from '@/api';
import CreateJudgesComponent from '@/views/Festival/components/judges/create-judges-component';
import RemoveItem from '@/components/modals/remove-item';
import debounce from 'debounce';

export default {
  name: 'FestivalTeams',
  components: {RemoveItem, CreateJudgesComponent, FestivalTabsComponent},
  data() {
    return {
      festival: null,
      form: {
        search: null,
        loading: false,
      },
      table: {
        loading: false,
        headers: [
          {
            text: 'УИН',
            align: 'start',
            value: 'profile',
          },
          {text: 'ФИО', value: 'fullname'},
          {text: 'Email', value: 'user.email', sortable: false},
          {text: '', value: 'actions',  width: '10%', align: 'right', sortable: false},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      page: {
        loading: true
      },
      selected: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('app', ['isMobile', 'isDesktop']),
    selectedJudges() {
      return {
        festival: this.festival,
        judges: this.selected
      }
    },
    canRemoveJudges() {
      return !this.selected.length
    }
  },
  methods: {
    ...mapActions('app', ['notify']),
    ...mapActions('festival', ['set_festival']),
    async fetch() {
      this.table.loading = true;
      try {
        let response = await api.festival.judges.all(this.$route.params.id, {
          search: this.form.search,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });
        this.festival = response.data.festival;
        this.set_festival(this.festival)
        this.table.list = response.data.list;
        this.table.meta = response.data.meta;
        this.page.loading = false
      } catch (e) {
        console.error(e);
      }
      this.table.loading = false;
    },
    async submit(){

    },
    addJudges(){
      this.$refs.judgesFormComponent.dialog = true;
    },
    optionsUpdated() {
      this.fetch();
    },
    deleteItem(item){
      this.selected = [item];
      this.$refs.remover.dialog = true;
    },
    removed(){
      this.notify('Судьи удалены');
      this.table.selected = []
      this.fetch();
      this.$refs.judgesFormComponent.getUsers()
    },
    judgesAdded(){
      this.fetch();
      this.notify('Судьи добавлены')
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
  },
}
</script>

<style scoped>

</style>
