<template>
  <div>
  <v-tabs
      v-model="active"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
  >
    <v-tabs-slider color="primary"></v-tabs-slider>

    <v-tab
        v-for="item in items"
        :key="item.id"
        :disabled="item.disabled"
        :to="item.to"
    >
      {{ item.name }}
    </v-tab>

  </v-tabs>
  <v-divider></v-divider>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: 'festival-tabs-component',
  data() {
    return {
      active: 'about',
    }
  },
  created() {
    this.active = this.getActive()
  },
  computed: {
    ...mapGetters('festival', ['festival']),

    items() {
      let disabled = false

      if (this.$route.name === 'festivals.create' || !this.festival) {
        disabled = true
      }


      return [
        {
          id: 'about',
          name: 'Основное',
          link: '',
          disabled: false,
          to: this.$route.params.id ? {name: 'festivals.edit', params: {id: this.$route.params.id}} : null
        }, {
          id: 'exercises',
          name: 'Испытания',
          link: '',
          disabled: disabled,
          to: this.$route.params.id ?  {name: 'festivals.exercises.index', params: {id: this.$route.params.id}}: null
        }, {
          id: 'teams',
          name: 'Команды',
          link: '',
          disabled: disabled || (this.festival.settings.credit_type === 'personal'),
          to: this.$route.params.id ? {name: 'festivals.teams.index', params: {id: this.$route.params.id}} : null
        }, {
          id: 'members',
          name: 'Участники',
          link: '',
          disabled: disabled,
          to: this.$route.params.id ? {name: 'festivals.members.index', params: {id: this.$route.params.id}}: null
        }, {
          id: 'judges',
          name: 'Судьи',
          link: '',
          disabled: disabled,
          to: this.$route.params.id ? {name: 'festivals.judges.index', params: {id: this.$route.params.id}}: null
        }, {
          id: 'interface',
          name: 'Интерфейс',
          link: '',
          disabled: disabled,
          to: this.$route.params.id ?  {name: 'festivals.interface.index', params: {id: this.$route.params.id}}: null
        }
      ];
    },
  },
  methods: {
    getActive() {
      switch (this.$route.name) {
        case 'festivals.exercises.index':
          return 1;
        case 'festivals.teams.index':
          return 2;
        case 'festivals.members.index':
          return 3;
        case 'festivals.judges.index':
          return 4;
        case 'festivals.interface.index':
          return 5;
        default:
          return 0
      }
    }
  }
}
</script>

<style scoped>

</style>