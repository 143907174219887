import {httpClient} from '@/modules/httpClient.js';

export const all = async (data = {}) => httpClient.get('/users', { params: data });
export const store = async (data = {}) => httpClient.post('/users', data);
export const update = async (id, data = {}) => httpClient.put(`/users/${id}`, data);
export const deleteUser = async (id) => httpClient.delete(`/users/${id}`);
export default {
    all,
    store,
    delete: deleteUser,
    update,
}
