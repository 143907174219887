var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0"},[_c('h5',{staticClass:"text-h6 pb-0"},[_vm._v("Место проведения")])])],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.countries,"filled":"","item-text":"text","item-value":"id","label":"Страна проведения","hide-details":"","dense":"","error-messages":errors},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Регион","rules":_vm.isRussiaSelected ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.regions.list,"label":"Регион","filled":"","loading":_vm.regions.loading,"item-value":"id","item-text":"name","return-object":"","hide-details":"","dense":"","error-messages":errors,"disabled":!_vm.isRussiaSelected},on:{"change":_vm.changeRegion},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.fullname)+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.fullname)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.zip))])],1)]}}],null,true),model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Муниципалитет"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.municipalities.list,"search-input":_vm.municipalities.search,"placeholder":"Начните вводить текст","label":"Муниципалитет","filled":"","loading":_vm.municipalities.loading,"item-value":"id","item-text":"name","no-filter":"","return-object":"","hide-details":"","dense":"","error-messages":errors,"disabled":!_vm.isRussiaSelected},on:{"update:searchInput":function($event){return _vm.$set(_vm.municipalities, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.municipalities, "search", $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.type)+" "+_vm._s(data.item.name)+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.type)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.zip))])],1)]}}],null,true),model:{value:(_vm.form.municipality),callback:function ($$v) {_vm.$set(_vm.form, "municipality", $$v)},expression:"form.municipality"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","search":"","label":"Название места проведения","dense":"","counter":"255","error-messages":errors,"disabled":!_vm.isRuLanguageSelected},model:{value:(_vm.form.title_ru),callback:function ($$v) {_vm.$set(_vm.form, "title_ru", $$v)},expression:"form.title_ru"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","label":"Название места проведения (EN)","dense":"","counter":"255","error-messages":errors,"disabled":!_vm.isEnLanguageSelected},model:{value:(_vm.form.title_en),callback:function ($$v) {_vm.$set(_vm.form, "title_en", $$v)},expression:"form.title_en"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","search":"","label":"Координаты места","dense":"","counter":"255","error-messages":errors},on:{"change":function($event){return _vm.coordinates()}},model:{value:(_vm.form.coordinates),callback:function ($$v) {_vm.$set(_vm.form, "coordinates", $$v)},expression:"form.coordinates"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","label":"Название координат","dense":"","hide-details":"","error-messages":errors},model:{value:(_vm.form.coordinates_name),callback:function ($$v) {_vm.$set(_vm.form, "coordinates_name", $$v)},expression:"form.coordinates_name"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"8"}},[_c('yandex-map',{attrs:{"settings":_vm.mapSettings,"coords":_vm.map.coords,"zoom":5},on:{"update:coords":function($event){return _vm.$set(_vm.map, "coords", $event)},"click":_vm.markerSetup}},[_c('ymap-marker',{attrs:{"marker-id":_vm.map.marker.id,"marker-type":_vm.map.marker.type,"coords":_vm.map.marker.coords,"options":_vm.map.marker.options},on:{"dragend":_vm.markerSetup}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }