const namespaced = true;

export default {
    namespaced,
    state: {
        festival: null,
    },
    actions: {
        set_festival: async ({commit,}, payload) => {
            await commit('SET_FESTIVAL', payload)
        },
        resetActiveSvod: ({commit, state}) => {
            if (state.timer) {
                clearTimeout(state.timer)
            }
            commit('SET_ACTIVE_SVOD', null)
        }
    },
    getters: {
        festival: state => {
            return state.festival
        }
    },
    mutations: {
        ['SET_FESTIVAL']: (state, payload) => {
            state.festival = payload
        },

    }
};
