<template>
  <div>
    <div v-if="page.loading" class="text-center pa-10">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
    <v-form v-else>
      <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
          passes
      >
      <v-row no-gutters class="pa-3 pb-0">
        <v-col cols="12" md="6">
          <ValidationProvider
              name="Название шаблона"
              rules="required"
              v-slot="{ errors }"
          >
          <v-text-field
              color="primary"
              class="mr-md-2"
              v-model="form.name"
              filled
              search
              label="Название шаблона"
              dense
              hide-details
              :disabled="form.loading || onlyShow"
              :error-messages="errors"
          >
          </v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
    <v-row class="mt-0 pt-0">
      <v-col cols="12">
        <templates-exercises-component
            :exercises="form.exercises"
            :exercises_list="exercises_list"
            :genders_list="genders_list"
            :age_categories_list="age_categories_list"
            :form_loading="form.loading"
            :only_show="onlyShow"
            ref="templateExercises"
        >
        </templates-exercises-component>
      </v-col>
    </v-row>
        <div v-if="!onlyShow">
          <div class="sticky-actions">
            <v-divider></v-divider>
            <v-row class="pa-3">
              <v-col cols="12">
                <v-btn color="primary"
                       :disabled="invalid || form.loading || ($refs.templateExercises && $refs.templateExercises.hasDuplicates)"
                       @click="submit" :loading="form.loading">
                  Сохранить
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </ValidationObserver>
    </v-form>

    <leave-form ref="leaveModal" @goto="dontSaveAndGoTo"></leave-form>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate/dist/vee-validate.full.esm';
setInteractionMode('aggressive');

import {mapState, mapActions} from 'vuex';
import TemplatesExercisesComponent from '@/views/Template/components/templates-exercises-component';
import {api} from '@/api'
import LeaveForm from '@/components/modals/leave-form';

export default {
  name: 'TemplateEdit',
  components: {LeaveForm, TemplatesExercisesComponent, ValidationProvider, ValidationObserver},
  data() {
    return {
      page: {
        loading: true
      },
      form: {
        name: null,
        loading: false,
        exercises: [],
      },
      exercises_list: [],
      age_categories_list: [],
      genders_list: [],
      startFormState: null,
      formState: null,
      dialog: false,
      goTo: null,
      dontSaveAndGo: false,
    }
  },
  mounted() {
    this.fetch();

  },
  computed: {
    ...mapState('auth', ['user']),
    onlyShow(){
      return this.$route.name === 'templates.show';
    }
  },
  methods: {
    ...mapActions('app', ['notify']),
    async fetch(){
      try {
         this.page.loading = true;
         let response = await api.template.edit(this.$route.params.id);
          this.exercises_list = response.data.exercises.list;
          this.age_categories_list = response.data.age_categories.list;
          this.genders_list = response.data.genders.list;

         this.form.name = response.data.item.name;
         this.form.exercises = response.data.item.exercises;
         this.page.loading = false;

        setTimeout(()=>{
          this.setFormState();
        }, 1000)
      } catch (e) {
        console.error(e)
      }
    },
    async submit(){
      try{
        this.form.loading = true;

        let data = {
          name: this.form.name,
          exercises:  JSON.parse(JSON.stringify(this.$refs.templateExercises.table.list.map((el) => ({
            id: el.id,
            exercise_id: el.exercise_id,
            gender_ids: el.gender_ids,
            age_category_ids: el.age_category_ids,
            priority: el.priority
          }))))
        }

        await api.template.update(this.$route.params.id, data)
        this.form.loading = false;
        this.setFormState()
        this.notify('Шаблон сохранен');
      } catch (e) {
        console.error(e);
      }
    },
    setFormState(){
      const redux = list => list.map(o => Object.fromEntries(
          ['exercise_id', 'gender_ids', 'age_category_ids', 'priority'].map(k => [k, o[k]])
      ));
      let state = {
        name: this.form.name,
        exercises: redux(this.$refs.templateExercises.table.list),
      }
      this.formState = JSON.stringify(state)
    },
    dontSaveAndGoTo(){
      this.dontSaveAndGo = true;
      this.$router.push(this.goTo)
    }
  },
  beforeRouteLeave (to, from, next) {

    if (!this.dontSaveAndGo) {
      const redux = list => list.map(o => Object.fromEntries(
          ['exercise_id', 'gender_ids', 'age_category_ids', 'priority'].map(k => [k, o[k]])
      ));

      let currentState = JSON.stringify({
        name: this.form.name,
        exercises: redux(this.$refs.templateExercises.table.list),
      });

      if (currentState !== this.formState) {
        this.$refs.leaveModal.dialog = true
        this.goTo = to
        return;
      }
    }

    next();
  },
}
</script>

<style scoped>

</style>
