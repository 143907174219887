<template>
  <v-row class="mb-4 align-center">
    <v-col cols="12" lg="6">
      <v-text-field
        label="Поиск по названию команды"
        filled
        hide-details
        clearable
        @input="$emit('search', $event)"
      />
    </v-col>

    <v-col cols="12" lg="6">
      <v-checkbox
        class="mt-0 pt-0"
        label="Автоматический расчет очков"
        hide-details
        :value="automaticCalculation"
        @change="$emit('automatic-calculation', Boolean($event))"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    automaticCalculation: {
      type: [Boolean, Number],
      default: false,
    },
  }
}
</script>
