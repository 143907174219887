<template>
  <div>
  <v-tabs
      v-model="active"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
  >
    <v-tabs-slider color="primary"></v-tabs-slider>

    <v-tab
        v-for="item in items"
        :key="item.id"
        :disabled="item.disabled"
        :to="item.to"
    >
      {{ item.name }}
    </v-tab>

  </v-tabs>
  <v-divider></v-divider>
  </div>
</template>

<script>

export default {
  name: 'festival-view-tabs-component',
  props: {
    hasRelay: {
      type: [Boolean, Number],
      default: false,
    }
  },
  data() {
    return {
      active: 'about'
    }
  },
  created() {
    this.active = this.getActive()
  },
  computed: {
    items() {
      let tabs = [
        {
          id: 'about',
          name: 'Турнирная таблица',
          link: '',
          disabled: false,
          to: this.$route.params.id ? {name: 'festivals.tournament-table.index', params: {id: this.$route.params.id}} : null
        }, {
          id: 'exercises',
          name: 'Ссылки на испытания',
          link: '',
          disabled: false,
          to: this.$route.params.id ?  {name: 'festivals.screens.index', params: {id: this.$route.params.id}}: null
        }, {
          id: 'teams',
          name: 'Таблица протоколов',
          link: '',
          disabled: false,
          to: this.$route.params.id ?  {name: 'festivals.protocols.index', params: {id: this.$route.params.id}}: null
        },
      ];

      if (this.hasRelay) {
        tabs.push({
          id: 'relay-race',
          name: 'Эстафета',
          link: '',
          disabled: false,
          to: this.$route.params.id ?  {name: 'festivals.relay-race.show', params: {id: this.$route.params.id}}: null
        })
      }

      if (this.$gates.hasRole('judge')){
        tabs.splice(0, 2);
      }

      return tabs
    },
  },
  methods: {
    getActive() {
      switch (this.$route.name) {
        case 'festivals.links.index':
          return 1;
        case 'festivals.protocols.index':
          return 2;
        default:
          return 0
      }
    }
  }
}
</script>
