import {httpClient} from '../modules/httpClient.js';

export const all = async (data = {}) => httpClient.get('/templates', { params: data });
export const create = async (data = {}) => httpClient.get('/templates/create', data);
export const edit = async (id, data = {}) => httpClient.get(`/templates/${id}/edit`, data);
export const get = async (id, data = {}) => httpClient.get(`/templates/${id}`, data);
export const store = async (data = {}) => httpClient.post('/templates', data);
export const update = async (id, data = {}) => httpClient.put(`/templates/${id}`, data);
export const deleteTemplate = async (id) => httpClient.delete(`/templates/${id}`);

export default {
    all,
    get,
    edit,
    store,
    update,
    create,
    delete: deleteTemplate,
}
