export const menu = [
    {
        text: 'Фестивали',
        icon: 'fas fa-home',
        name: 'festivals.index',
    },
    {
        text: 'Свод',
        icon: 'fas fa-clock',
        name: 'svod.index',
    },
    {
        text: 'Шаблоны',
        icon: 'fas fa-bookmark',
        name: 'templates.index',
    },
    {
        text: 'Справочник',
        icon: 'fas fa-question-circle',
        name: 'exercises.index',
    },
    {
        text: 'Пользователи',
        icon: 'fas fa-user-friends',
        name: 'users.index',
    },
    {
        text: 'Действия пользователей',
        icon: 'fas fa-list-ol',
        name: 'logs.index',
    },
    {
        text: 'Аналитика',
        icon: 'fas fa-chart-bar',
        name: 'analytics.index',
    },
    {
        text: 'Перейти в МП для организаторов',
        icon: 'fas fa-mobile-alt',
        name: 'mobile.index'
    },
];
