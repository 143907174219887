var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',{attrs:{"dense":""}},[[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Название","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","label":"Название","dense":"","hide-details":"","error-messages":errors},model:{value:(_vm.form.title_ru),callback:function ($$v) {_vm.$set(_vm.form, "title_ru", $$v)},expression:"form.title_ru"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Название (EN)","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","label":"Название (EN)","dense":"","hide-details":"","error-messages":errors},model:{value:(_vm.form.title_en),callback:function ($$v) {_vm.$set(_vm.form, "title_en", $$v)},expression:"form.title_en"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Описание","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"color":"primary","filled":"","label":"Описание","auto-grow":"","dense":"","error-messages":errors,"hide-details":""},model:{value:(_vm.form.description_ru),callback:function ($$v) {_vm.$set(_vm.form, "description_ru", $$v)},expression:"form.description_ru"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Описание (EN)","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"color":"primary","filled":"","label":"Описание (EN)","auto-grow":"","dense":"","error-messages":errors,"hide-details":""},model:{value:(_vm.form.description_en),callback:function ($$v) {_vm.$set(_vm.form, "description_en", $$v)},expression:"form.description_en"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Параметр расчета","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filled":"","dense":"","label":"Параметр расчета","item-text":"title","item-value":"id","items":_vm.parametersList,"disabled":_vm.isEdit,"hide-details":"","error-messages":errors},model:{value:(_vm.form.exercise_parameter_id),callback:function ($$v) {_vm.$set(_vm.form, "exercise_parameter_id", $$v)},expression:"form.exercise_parameter_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Еденица расчета","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filled":"","dense":"","hide-details":"","item-text":"title","item-value":"id","items":_vm.unitsList,"error-messages":errors,"disabled":_vm.isEdit,"label":"Единица расчета"},model:{value:(_vm.form.exercise_unit_id),callback:function ($$v) {_vm.$set(_vm.form, "exercise_unit_id", $$v)},expression:"form.exercise_unit_id"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Ссылка на видео","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","label":"Ссылка на видео","dense":"","hide-details":"","error-messages":errors},model:{value:(_vm.form.video_link),callback:function ($$v) {_vm.$set(_vm.form, "video_link", $$v)},expression:"form.video_link"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('image-upload-component',{ref:"imageUploader",attrs:{"label":'Иконка',"disabled":_vm.loading,"height":'50px',"width":'50px',"file-data":_vm.form.icon,"file":_vm.form.icon},on:{"update:file":function($event){return _vm.$set(_vm.form, "icon", $event)}}})],1)],1)]],2),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Отменить ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }