<template>
  <div>
    <festival-view-tabs-component :has-relay="hasRelay" />
    <p class="headline pa-3 mb-0" v-if="exercise">{{ exercise.title_ru || exercise.title_en }}</p>

    <FestivalProtocolFilter
      :age-list="getAgeList"
      :gender-list="getGenderList"
      :team-list="getTeamList"
      @ages-change="filterBy.ageCategories = $event"
      @genders-change="filterBy.genders = $event"
      @teams-change="filterBy.teams = $event"
    />

    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" md="6" class="mb-3 mb-md-0">
        <v-btn color="primary" class="mr-3" outlined text
               @click="importDialog.show = true">Импорт протокола
        </v-btn>

        <v-btn color="primary" :loading="template.loading" :disabled="template.loading" outlined text
               @click="downloadTemplate()">Скачать шаблон
        </v-btn>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск по УИН, ФИО"
            dense
            @keyup="search"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template v-slot:item.member.age_category.id="{ item }">
            {{ item.member.age_category.description }}
          </template>
          <template v-slot:item.value="{ item }">
            <v-text-field
              v-model="item.value"
              filled
              dense
              :loading="item.loading"
              :disabled="item.loading || item.member_involve_status !== statusInvolved"
              @blur="setValue(item, $event)"
              :placeholder="placeHolder"
              v-mask="inputMask"
              hide-details
            ></v-text-field>
          </template>
          <template v-slot:item.member.member_involve_status="{ item }">
            <FestivalProtocolMemberStatusSelect
              :value="item.member_involve_status"
              :busy="item.loading"
              @change="setMemberStatus(item, $event)"
            />
          </template>
          <template v-if="devMode" v-slot:item.points="{ item }">
            <v-text-field
                v-model="item.points"
                filled
                dense
                :loading="item.loading"
                :disabled="item.loading"
                @blur="setPoints(item, $event)"
                hide-details
            ></v-text-field>
            <v-alert type="warning">
              Это поле доступно для редактирования, потому что включен режим разработчика
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
        v-model="importDialog.show"
        persistent
        max-width="480"
    >
      <v-form>
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card>
            <v-card-title class="text-h5">
              Импортировать протокол
            </v-card-title>
            <v-card-text>Загрузка результатов из таблицы</v-card-text>
            <v-card-text>
                <ValidationProvider
                    name="Название"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-file-input
                      v-model="form.file"
                      label="Выберите файл в формате .xlsx"
                      class="ma-0 pa-0"
                      hide-details
                      fille
                      dense
                      filled
                      ref="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      :error-messages="errors"
                      :disabled="importDialog.loading"
                  ></v-file-input>
                </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  outlined
                  text
                  @click="importDialog.show = false"
                  v-show="!importDialog.loading"
              >
                Отменить
              </v-btn>
              <v-btn
                  color="primary"
                  :disabled="invalid || importDialog.loading"
                  :loading="importDialog.loading"
                  @click="importTemplate()"
              >
                Импортировать
              </v-btn>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-form>
    </v-dialog>

  </div>
</template>
<script>
import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';
import debounce from 'debounce'
import FestivalProtocolFilter from '@/views/Festival/components/FestivalProtocolFilter.vue'
import FestivalProtocolMemberStatusSelect from '@/views/Festival/components/FestivalProtocolMemberStatusSelect.vue'
import { STATUS_INVOLVED } from '@/constants/festival'
import {mapState, mapActions, mapGetters} from 'vuex';
import {api} from '@/api';
import FestivalViewTabsComponent from '@/views/Festival/components/festival-view-tabs-component';

setInteractionMode('eager');

export default {
  name: 'FestivalProtocolIndex',
  components: {
    FestivalViewTabsComponent,
    FestivalProtocolFilter,
    FestivalProtocolMemberStatusSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      form: {
        search: null,
        onlyMine: false,
        loading: false,
        role: {
          selected: 'all'
        }
      },
      filterBy: {
        ageCategories: [],
        genders: [],
        teams: [],
      },
      table: {
        headers: [
          {
            text: 'ID',
            align: 'start',
            value: 'member.id',
            width: '7%'
          },
          {text: 'Команда', value: 'member.team.teamable.name'},
          {text: 'УИН', value: 'member.uin'},
          {text: 'ФИО', value: 'member.fullname'},
          {text: 'Пол', value: 'member.gender.name_ru' },
          {text: 'Категория', value: 'member.age_category.id'},
          {text: 'Результат', value: 'value', width: '15%'},
          {text: 'Балл', value: 'points', width: '10%'},
          {text: 'Результат участия', value: 'member.member_involve_status'}
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      selected: null,
      exercise: null,
      festival: null,
      template: {
        loading: false
      },
      importDialog: {
        show: false,
        loading: false,
        batch_id: null,
        interval: null
      }
    }
  },
  watch: {
    filterBy: {
      handler() {
        this.fetch()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('app', ['devMode']),

    hasRelay() {
      return this.festival?.has_relay;
    },

    statusInvolved: () => STATUS_INVOLVED,

    /**
     * Список возрастных категорий.
     */
    getAgeList() {
      const ageList = {}

      this.festival?.exercises.forEach((exercise) => {
        exercise.ageCategories.list.forEach((item) => {
          if (! Object.hasOwn(ageList, item.id)) {
            ageList[item.id] = { id: item.id, name: item.description }
          }
        })
      })

      return Object.values(ageList)
    },

    /**
     * Список команд.
     */
    getTeamList() {
      const teams = []

      if (this.festival && this.festival.has_teams) {
        this.festival.teams.list.forEach((team) => {
          teams.push({ id: team.id, name: team.teamable.name })
        })
      }

      return teams
    },

    /**
     * Список полов.
     */
    getGenderList() {
      const genderList = {}

      if (this.festival && this.festival?.exercises) {
        exerciseLoop: for (const exercise of this.festival.exercises) {
          for (const gender of exercise.genders.list) {
            if (! Object.hasOwn(genderList, gender.id)) {
              genderList[gender.id] = {id: gender.id, name: gender.name_ru}
            }

            if (Object.keys(genderList).length === 2) {
              break exerciseLoop
            }
          }
        }
      }

      return Object.values(genderList)
    },

    inputMask() {
      switch (this.exercise.unit.name) {
        case 'short_distance':
        case 'meters':
          return '##.##';
        case 'long_distance':
          return '##:##.##';
        case 'number_of':
        case 'number_of_points':
        case 'centimeters':
          return [/\d/, /\d/, /\d/, /\d/]
        case 'negative_value':
          return (value) => {
            if (value.charAt(0) === '-') {
              return ['-', /\d/, /\d/]
            } else {
              return [/\d/, /\d/]
            }
          }
      }
      return null
    },
    placeHolder() {
      switch (this.exercise.unit.name) {
        case 'short_distance':
        case 'meters':
          return '00.00'
        case 'long_distance':
          return '00:00.00';
        case 'number_of':
        case 'number_of_points':
        case 'centimeters':
          return '0'
        case 'negative_value':
          return '±0'
      }
      return null
    }
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),

    prepareSortBy(key) {
      if (key === 'member.gender.name_ru') {
        return 'gender'
      }
      if (key === 'member.team.teamable.name') {
        return 'team'
      }
      if (key === 'member.age_category.id') {
        return 'ageCategory'
      }

      return key || null
    },

    async fetch() {
      if (this.form.loading) return

      this.form.loading = true

      try {
        let response = await api.festival.protocols.get(this.$route.params.id, this.$route.params.exercise, {
          search: this.form.search,
          only_mine: this.form.onlyMine,
          role: this.form.role.selected,
          sortBy: this.prepareSortBy(this.table.options.sortBy[0]),
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page,
          ...this.filterBy,
        });

        this.table.list = response.data.list;
        this.table.meta = response.data.meta;
        this.exercise = response.data.exercise;
        this.festival = response.data.festival;
      } catch (e) {
        console.error(e);
      } finally {
        this.form.loading = false;
      }
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated() {
      this.fetch()
    },

    async setAgeCategory(item, ageCategoryId) {
      if (item.loading) return

      this.$set(item, 'loading', true)

      try {
        await api.festival.members.update(
          this.$route.params.id,
          item.member.id,
          {
            ...item.member,
            age_category_id: ageCategoryId
          }
        )
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {
          this.$set(item, 'loading', false)
        },3000)
      }
    },

    /**
     * Изменяет статус участника.
     *
     * @param item
     * @param value
     *
     * @returns {Promise<void>}
     */
    async setMemberStatus(item, value) {
      if (item.loading) return

      this.$set(item, 'loading', true)

      item.member_involve_status = value

      try {
        await api.festival.protocols.update(
          this.$route.params.id,
          this.$route.params.exercise,
          item.id,
          {
            member_involve_status: value
          })
      } catch (error) {
        console.error(error)
      } finally {
        this.$set(item, 'loading', false)
      }
    },

    async setValue(item, e) {
      try {
        item.value = this.convertValue(item.value);

        //Проблема в том что после маски с отризательным значением, потому что там маска как функция, не работает реактивный инпут
        //Поэтому берем элемент и пихаем туда значение напрямую
        if (this.exercise.unit.name === 'negative_value') {
          item.value = parseInt(item.value)
          e.target.value = item.value
        }

        this.$set(item, 'loading', true)
        let response = await api.festival.protocols.update(
          this.$route.params.id,
          this.$route.params.exercise,
          item.id,
          {
            value: item.value,
            member_involve_status: item.member_involve_status
          });
        item.loading = false;
        item.points = response.data.points;

      } catch (e) {
        console.error(e)
      }
    },
    async setPoints(item) {
      await api.festival.protocols.update(this.$route.params.id, this.$route.params.exercise, item.id, {
        value: item.value,
        points: item.points
      });
    },
    convertValue(value) {
      if (value.length === 0) {
        return null;
      }

      if (['short_distance', 'meters'].includes(this.exercise.unit.name)) {
        let values = ['00', '00'];
        let split = value.split('.');
        (split[0] && split[0].length === 1) ? values[0] = `0${split[0]}` : values[0] = split[0];
        if (split[1] && split[1].length === 1) {
          values[1] = `${split[1]}0`
        } else if (split[1] && split[1].length === 2) {
          values[1] = split[1];
        }
        return values.join('.');
      } else if (this.exercise.unit.name === 'long_distance') {
        let values = ['00', '00', '00'];
        let split = value.split(/:|\./);
        (split[0] && split[0].length === 1) ? values[0] = `0${split[0]}` : values[0] = split[0];

        if (split[1] && split[1].length === 1) {
          values[1] = `0${split[1]}`
        } else if (split[1] && split[1].length === 2) {
          values[1] = split[1];
        }

        if (split[2] && split[2].length === 1) {
          values[2] = `${split[2]}0`;
        } else if (split[2] && split[2].length === 2) {
          values[2] = split[2];
        }

        return `${values[0]}:${values[1]}.${values[[2]]}`;
      } else if (['number_of', 'number_of_points', 'centimeters'].includes(this.exercise.unit.name)) {
        return value;
      } else if (['negative_value'].includes(this.exercise.unit.name)) {
        let values = ['00', '0'];
        let negative = false;

        if (value.charAt(0) === '-') {
          negative = true;
          value = value.replace('-', '');
        }

        let split = value.split('.');
        (split[0] && split[0].length === 1) ? values[0] = `0${split[0]}` : values[0] = split[0];
        if (split[1] && split[1].length === 1) {
          values[1] = split[1]
        }

        if (negative) {
          values[0] = `-${values[0]}`;
        }

        return values.join('.');
      }

      return null;
    },
    async downloadTemplate() {
      this.template.loading = true;
      try {
        let response = await api.festival.protocols.template.download(this.$route.params.id, this.$route.params.exercise)
        this.download(response.data.template_url, 'template.xlsx')
      } catch (e) {
        console.error(e);
      }
      this.template.loading = false
    },
    async importTemplate() {
      this.importDialog.loading = true;
      try {
        let formData = new FormData();
        formData.append('file', this.$refs.file.value);
        const res = await api.festival.protocols.template.upload(this.$route.params.id, this.$route.params.exercise, formData)
        this.importDialog.batch_id = res.data.batch_id
        this.importDialog.interval = setInterval(() => {
          this.importTemplateCheckStatus()
        }, 5000);
      } catch (e) {
        this.error('Произошла ошибка при загрузке файла.');
      }
    },
    async importTemplateCheckStatus() {
      try {
        const res = await api.festival.protocols.template.status(this.$route.params.id, this.$route.params.exercise, {batch_id: this.importDialog.batch_id});
        if (res.data.status === 'completed') {
          this.importDialog.loading = false;
          this.importDialog.show = false;
          clearInterval(this.importDialog.interval);
          this.notify('Файл успешно загружен');
          await this.fetch();
        }

        if (res.data.status === 'error') {
          this.importDialog.loading = false;
          this.importDialog.show = false;
          clearInterval(this.importDialog.interval);
          this.error('Произошла ошибка при обработке файла.' + res.data.messages.join(' '));
        }

      } catch (e) {
        this.importDialog.loading = false;
        this.importDialog.show = false;
        this.error('Произошла ошибка при загрузке файла.')
        clearInterval(this.importDialog.interval);
      }
    },
    download(dataurl, filename) {
      let a = document.createElement('a');
      a.href = dataurl;
      a.setAttribute('download', filename);
      a.click();
    }
  },
}
</script>
