import {api} from '@/api';

const namespaced = true;
export default {
    namespaced,
    state: {
        is_loading: false,
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        gates: []
    },
    actions: {
        login: async ({commit}, {email, password, pin}) => {
            return api.auth.login({
                email,
                password,
                pin
            })
                .then(({data}) => {
                    const {token, user} = data;
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    commit('auth_success', {token: token, user: user});
               return user;
           });
        }
    },
    getters: {
        isLoggedIn: state => {
            return !!state.token && state.token !== 'null';
        },
    },
    mutations: {
        ['auth_success']: (state, payload) => {
            state.token = payload.token;
            state.user = payload.user;
            state.loggedIn = true;
        },
        ['auth_error']: (state, payload) => {
            state.error = payload;
        },
        ['set_loading']: (state, payload) => {
            state.is_loading = payload;
        },
        ['set_user']: (state, payload) => {
            state.user = payload;
            localStorage.setItem('user', JSON.stringify(payload));
        },
    }
};
