import {guard} from '@/guards/guard';
import Profile from '@/views/Profile/Profile.vue';
import ProfileEdit from '@/views/Profile/ProfileEdit.vue';

export default {
    path: '/profile',
    component: Profile,
    meta: {title: 'Профиль'},
    children: [
        {path: '', name: 'profile.edit', beforeEnter: guard, component: ProfileEdit, meta: {title: 'Редактировать профиль'}},
    ]
}
