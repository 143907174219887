<template>
  <VRow class="pa-3" cols="3">
    <VCol>
      <VSelect
        :items="teamList"
        label="Команда"
        item-value="id"
        item-text="name"
        filled
        clearable
        multiple
        @change="$emit('teams-change', $event)"
      />
    </VCol>
    <VCol>
      <VSelect
        :items="genderList"
        label="Пол"
        item-value="id"
        item-text="name"
        filled
        clearable
        multiple
        @change="$emit('genders-change', $event)"
      />
    </VCol>
    <VCol>
      <VSelect
        :items="ageList"
        label="Возрастная категория"
        item-value="id"
        item-text="name"
        filled
        clearable
        multiple
        @change="$emit('ages-change', $event)"
      />
    </VCol>
  </VRow>
</template>

<script>
export default {
  props: {
    ageList: {
      type: Array,
      default: () => [],
    },
    teamList: {
      type: Array,
      default: () => [],
    },
    genderList: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
