<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template v-slot:item.subsection="{ item }">
            {{ item.subsection ? item.subsection : '-' }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ (new Date(item.created_at)).toLocaleDateString() }} {{
              (new Date(item.created_at)).toLocaleTimeString()
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>
<script>


import {api} from '@/api'
import {mapState, mapActions} from 'vuex';

export default {
  name: 'LogsIndex',
  data() {
    return {
      table: {
        headers: [
          {text: 'УИН', value: 'uin'},
          {text: 'ФИО', value: 'user', sortable: true},
          {text: 'Раздел', value: 'section', sortable: true},
          {text: 'Подраздел', value: 'subsection', sortable: true},
          {text: 'Действие', value: 'action', sortable: true},
          {text: 'Время', value: 'created_at', sortable: true},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      form: {
        loading: false
      },
      svod: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify']),
    async fetch() {
      try {
        this.form.loading = true;
        let response = await api.logs.index({
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });
        this.table.list = response.data.list
        this.table.meta = response.data.meta
        this.svod = response.data.item
        this.form.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    optionsUpdated() {

      this.fetch()
    },
  },
}
</script>

<style scoped>

</style>
