<template>
  <v-container fill-height class="loginOverlay">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm6 md4 elevation-6>
        <v-card>
          <v-card-title class="pb-0">
            <v-img
                contain
                max-height="95"
                max-width="206"
                src="/images/logo-wide.svg"
            ></v-img>
          </v-card-title>
          <v-card-text class="pt-4 pt-0">
            <ValidationObserver
                ref="observer"
                v-slot="{ invalid, handleSubmit }"
            >
              <v-form ref="form">
                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="error"
                >
                  {{ error }}
                </v-alert>

                <ValidationProvider
                    name="Новый пароль"
                    rules="required|min:8|max:16|alpha_dash"
                    v-slot="{ errors }"
                    mode="eager"
                    vid="password"
                >
                  <v-text-field
                    label="Новый пароль"
                    v-model="form.password"
                    :error-messages="errors"
                    :append-icon="!showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    :type="!showPass ? 'password' : 'text'"
                    counter
                    filled
                    dense
                    immediate
                    :readonly="is_loading"
                  >
                  </v-text-field>
                </ValidationProvider>

                <ValidationProvider
                  name="Повторите пароль"
                  rules="required|min:8|max:16|confirmed:password|alpha_dash"
                  v-slot="{ errors }"
                  mode="eager"
                >
                  <v-text-field
                    label="Повторите пароль"
                    v-model="form.repeat_password"
                    :error-messages="errors"
                    :append-icon="!showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    :type="!showPass ? 'password' : 'text'"
                    counter
                    filled
                    dense
                    immediate
                    :readonly="is_loading"
                  >
                  </v-text-field>
                </ValidationProvider>


                <v-layout justify-space-between>
                  <v-btn
                      @click.prevent="handleSubmit(submit)"
                      class="primary"
                      :disabled="invalid || is_loading"
                      :loading="is_loading"
                  >
                    Восстановить
                  </v-btn>

                  <v-btn
                    :to="{ name: 'auth.login' }"
                  >
                    Отмена
                  </v-btn>

                </v-layout>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate';
import {api} from '@/api';

setInteractionMode('eager');


export default {
  name: 'RestorePassword',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {
        password: null,
        repeat_password: null,
        token: null
      },
      showPass: false,
      error: null,
      is_loading: false,
    };
  },
  async created(){
    if (!this.$route.query.token){
      await this.$router.push({name: 'auth.login'})
    }

    this.form.token = this.$route.query.token
  },
  computed: {
  },
  methods: {
    async submit() {
      try {
        await api.auth.restorePassword(this.form)
        await this.$router.push({name: 'auth.login'})
      } catch (e) {
        console.log(e)
      }
    }
  }
};
</script>
