import auth from '@/api/auth.js';
import festival from '@/api/festival.js';
import user from '@/api/user.js';
import gto from '@/api/gto.js';
import kladr from '@/api/kladr.js';
import role from '@/api/role.js';
import exercise from '@/api/exercise.js';
import svod from '@/api/svod.js';
import file from '@/api/file.js';
import parameter_calc from '@/api/parameter_calc.js';
import unit_calc from '@/api/unit_calc.js';
import template from '@/api/template.js';
import country from '@/api/country.js';
import developer from '@/api/developer';
import logs from '@/api/logs';
import profile from '@/api/profile';

export const api = {
    auth,
    festival,
    user,
    gto,
    kladr,
    role,
    exercise,
    svod,
    file,
    parameter_calc,
    unit_calc,
    template,
    country,
    developer,
    logs,
    profile
}
