var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('ValidationProvider',{attrs:{"rules":"required","skip-if-empty":false,"name":"Имя"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Имя","error-messages":errors,"dense":""},model:{value:(_vm.form.profile.name),callback:function ($$v) {_vm.$set(_vm.form.profile, "name", $$v)},expression:"form.profile.name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('ValidationProvider',{attrs:{"rules":"required","skip-if-empty":false,"name":"Фамилия"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Фамилия","error-messages":errors,"dense":""},model:{value:(_vm.form.profile.surname),callback:function ($$v) {_vm.$set(_vm.form.profile, "surname", $$v)},expression:"form.profile.surname"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('ValidationProvider',{attrs:{"skip-if-empty":false,"name":"Отчество"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Отчество","error-messages":errors,"dense":""},model:{value:(_vm.form.profile.middlename),callback:function ($$v) {_vm.$set(_vm.form.profile, "middlename", $$v)},expression:"form.profile.middlename"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('ValidationProvider',{attrs:{"skip-if-empty":false,"name":"УИН"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"УИН","error-messages":errors,"dense":""},model:{value:(_vm.form.profile.uin),callback:function ($$v) {_vm.$set(_vm.form.profile, "uin", $$v)},expression:"form.profile.uin"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('ValidationProvider',{attrs:{"rules":"required|email","skip-if-empty":false,"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Email","error-messages":errors,"dense":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list-item-title',{staticClass:"pa-0"},[_c('strong',[_vm._v("Роль пользователя")])]),_c('v-radio-group',{ref:"role",staticClass:"mt-2",attrs:{"row":"","hide-details":"","readonly":_vm.loading},on:{"change":function($event){return _vm.removeMunicipality()}},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}},[_c('v-radio',{attrs:{"label":"Региональный","value":"regional_operator","disabled":!_vm.$gates.hasPermission('users.edit.set.region')}}),_c('v-radio',{attrs:{"label":"Муниципалитет","disabled":_vm.isEdit && _vm.isJudge,"value":"municipal_operator","readonly":!_vm.$gates.hasPermission('users.edit.set.municipality')}}),_c('v-radio',{attrs:{"label":"Судья","disabled":_vm.isEdit && (_vm.isRegional || _vm.isMunicipal),"value":"judge"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Регион","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{class:{'disabled-autocomplete': _vm.loading || !_vm.$gates.hasPermission('users.edit.set.region')},attrs:{"items":_vm.regionsData,"label":"Регион","filled":"","item-value":"id","item-text":"name","placeholder":"Начните вводить текст","return-object":"","hide-details":"","dense":"","error-messages":errors,"readonly":_vm.loading || !_vm.$gates.hasPermission('users.edit.set.region')},on:{"change":_vm.removeMunicipality},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.fullname)+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.fullname)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.zip))])],1)]}}],null,true),model:{value:(_vm.form.regions.selected),callback:function ($$v) {_vm.$set(_vm.form.regions, "selected", $$v)},expression:"form.regions.selected"}})]}}],null,true)})],1)],1),(!_vm.isJudge)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Муниципалитет","rules":(_vm.isMunicipal) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.form.municipals.list,"search-input":_vm.municipalSearchString,"label":"Муниципалитет","filled":"","loading":_vm.form.municipals.loading,"item-value":"id","item-text":"name","no-filter":"","placeholder":"Начните вводить текст","return-object":"","hide-details":"","dense":"","disabled":!_vm.isMunicipal || !_vm.form.regions.selected,"error-messages":errors,"readonly":_vm.loading},on:{"update:searchInput":function($event){_vm.municipalSearchString=$event},"update:search-input":function($event){_vm.municipalSearchString=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.type)+" "+_vm._s(data.item.name)+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.type)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.zip))])],1)]}}],null,true),model:{value:(_vm.form.municipals.selected),callback:function ($$v) {_vm.$set(_vm.form.municipals, "selected", $$v)},expression:"form.municipals.selected"}})]}}],null,true)})],1)],1):_vm._e(),(!_vm.isJudge)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":'Доступ к разделу аналитика',"hide-details":""},model:{value:(_vm.form.access_analytics),callback:function ($$v) {_vm.$set(_vm.form, "access_analytics", $$v)},expression:"form.access_analytics"}})],1)],1):_vm._e()],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Отменить ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }