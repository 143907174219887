import {api} from '@/api';

const namespaced = true;

export default {
    namespaced,
    state: {
        activeSvod: null,
        disabled_dates: [],
        timer: null
    },
    actions: {
        getActiveSvod: async ({commit, state, dispatch, rootGetters}) => {
            try {
                let response = await api.svod.active();
                commit('SET_ACTIVE_SVOD', response.data.item);
                state.disabled_dates = response.data.disabled_dates;
                if (state.timer){
                    clearTimeout(state.timer);
                }

                state.timer = setTimeout(function () {
                    if(rootGetters['auth/isLoggedIn']) {
                        dispatch('getActiveSvod')
                    }
                }, 10000);

            } catch (e) {
                console.error(e);
            }
        },
        resetActiveSvod: ({commit, state}) => {
            if (state.timer){
                clearTimeout(state.timer)
            }
            commit('SET_ACTIVE_SVOD', null)
        }
    },
    getters: {
        isSvodProcessing: state => {
            if (state.activeSvod) {
                let processingStatuses = ['uploaded', 'validating', 'processing'];
                return processingStatuses.includes(state.activeSvod.status);
            }

            return false;
        },
        isSvodProcessed: state => {
            return (state.activeSvod && state.activeSvod.status === 'processed')
        },
        isSvodFailed: state => {
            return (state.activeSvod && state.activeSvod.status === 'failed')
        },
        showProgressBar: (state, getters) => {
            return ((getters.isSvodProcessing || getters.isSvodFailed) && !!state.activeSvod)
        },
    },
    mutations: {
        ['SET_ACTIVE_SVOD']: (state, payload) => {
            state.activeSvod = payload
        },

    }
};
