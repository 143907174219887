import {guard} from '@/guards/guard';
import Festival from '@/views/Festival/Festival';
import FestivalIndex from '@/views/Festival/FestivalIndex';
import FestivalCreate from '@/views/Festival/FestivalCreate';
import FestivalEdit from '@/views/Festival/FestivalEdit';
import FestivalExercises from '@/views/Festival/FestivalExercises';
import FestivalMembers from '@/views/Festival/FestivalMembers';
import FestivalInterface from '@/views/Festival/FestivalInterface';
import FestivalTeams from '@/views/Festival/FestivalTeams';
import FestivalJudges from '@/views/Festival/FestivalJudges';
import FestivalTournamentTable from '@/views/Festival/FestivalTournamentTable';
import FestivalScreens from '@/views/Festival/FestivalScreens';
import FestivalProtocols from '@/views/Festival/Protocol/FestivalProtocolIndex';
import FestivalProtocolView from '@/views/Festival/Protocol/FestivalProtocolView';
import FestivalRelayRace from "@/views/Festival/FestivalRelayRace.vue";

export default {
    path: '/festivals',
    component: Festival,
    children: [
        {path: '', name: 'festivals.index', beforeEnter: guard, component: FestivalIndex, meta: {title: 'Фестивали'}},
        {
            path: 'create',
            name: 'festivals.create',
            beforeEnter: guard,
            component: FestivalCreate,
            meta: {title: 'Добавить фестиваль', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/edit',
            name: 'festivals.edit',
            beforeEnter: guard,
            component: FestivalEdit,
            meta: {title: 'Редактировать фестиваль', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/exercises',
            name: 'festivals.exercises.index',
            beforeEnter: guard,
            component: FestivalExercises,
            meta: {title: 'Редактировать фестиваль', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/members',
            name: 'festivals.members.index',
            beforeEnter: guard,
            component: FestivalMembers,
            meta: {title: 'Редактировать фестиваль', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/interface',
            name: 'festivals.interface.index',
            beforeEnter: guard,
            component: FestivalInterface,
            meta: {title: 'Редактировать фестиваль', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/teams',
            name: 'festivals.teams.index',
            beforeEnter: guard,
            component: FestivalTeams,
            meta: {title: 'Редактировать фестиваль', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/judges',
            name: 'festivals.judges.index',
            beforeEnter: guard,
            component: FestivalJudges,
            meta: {title: 'Редактировать фестиваль', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/tournament-table',
            name: 'festivals.tournament-table.index',
            beforeEnter: guard,
            component: FestivalTournamentTable,
            meta: {title: 'Просмотр фестиваля', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/screens',
            name: 'festivals.screens.index',
            beforeEnter: guard,
            component: FestivalScreens,
            meta: {title: 'Просмотр фестиваля', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/protocols',
            name: 'festivals.protocols.index',
            beforeEnter: guard,
            component: FestivalProtocols,
            meta: {title: 'Просмотр фестиваля', goBackTo: 'festivals.index'}
        },
        {
            path: ':id/protocols/:exercise',
            name: 'festivals.protocols.show',
            beforeEnter: guard,
            component: FestivalProtocolView,
            meta: {title: 'Просмотр фестиваля', goBackTo: {name: 'festivals.protocols.index', params: {id: ':id'}}}
        },
        {
            path: ':id/relay-race',
            name: 'festivals.relay-race.show',
            // beforeEnter: guard,
            component: FestivalRelayRace,
            meta: {title: 'Просмотр фестиваля', goBackTo: {name: 'festivals.index', params: {id: ':id'}}}
        },
    ]
}
