<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-card class="mx-auto">
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card-title>
            {{ title }}
          </v-card-title>

          <v-card-text>

            <v-row>
              <v-col class="pb-0">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :skip-if-empty="false"
                  name="Имя"
                >
                  <v-text-field
                    color="primary"
                    class="mr-md-2"
                    v-model="form.profile.name"
                    filled
                    label="Имя"
                    :error-messages="errors"
                    dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :skip-if-empty="false"
                  name="Фамилия"
                >
                  <v-text-field
                    color="primary"
                    class="mr-md-2"
                    v-model="form.profile.surname"
                    filled
                    label="Фамилия"
                    :error-messages="errors"
                    dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  :skip-if-empty="false"
                  name="Отчество"
                >
                  <v-text-field
                    color="primary"
                    class="mr-md-2"
                    v-model="form.profile.middlename"
                    filled
                    label="Отчество"
                    :error-messages="errors"
                    dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  :skip-if-empty="false"
                  name="УИН"
                >
                  <v-text-field
                    color="primary"
                    class="mr-md-2"
                    v-model="form.profile.uin"
                    filled
                    label="УИН"
                    :error-messages="errors"
                    dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <ValidationProvider
                  rules="required|email"
                  v-slot="{ errors }"
                  :skip-if-empty="false"
                  name="Email"
                >
                  <v-text-field
                    color="primary"
                    class="mr-md-2"
                    v-model="form.email"
                    filled
                    label="Email"
                    :error-messages="errors"
                    dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

              <v-row>
                <v-col
                    cols="12"
                >

                  <v-list-item-title class="pa-0"><strong>Роль пользователя</strong></v-list-item-title>
                  <v-radio-group
                      v-model="form.role"
                      row
                      class="mt-2"
                      hide-details
                      ref="role"

                      :readonly="loading"
                      @change="removeMunicipality()"
                  >
                    <v-radio
                        label="Региональный"
                        value="regional_operator"
                        :disabled="!$gates.hasPermission('users.edit.set.region')"
                    ></v-radio>
                    <v-radio
                        label="Муниципалитет"
                        :disabled="isEdit && isJudge"
                        value="municipal_operator"
                        :readonly="!$gates.hasPermission('users.edit.set.municipality')"
                    ></v-radio>
                    <v-radio
                      label="Судья"
                      :disabled="isEdit && (isRegional || isMunicipal)"
                      value="judge"
                    ></v-radio>
                  </v-radio-group>

                </v-col>
              </v-row>
              <v-row>

                <v-col
                    cols="12"
                >
                  <ValidationProvider
                      name="Регион"
                      rules="required"
                      v-slot="{ errors }"
                  >
                    <v-autocomplete
                        v-model="form.regions.selected"
                        :items="regionsData"
                        label="Регион"
                        filled
                        item-value="id"
                        item-text="name"
                        placeholder="Начните вводить текст"
                        return-object
                        hide-details
                        @change="removeMunicipality"
                        dense
                        :error-messages="errors"
                        :readonly="loading || !$gates.hasPermission('users.edit.set.region')"
                        :class="{'disabled-autocomplete': loading || !$gates.hasPermission('users.edit.set.region')}"
                    >
                      <template v-slot:selection="data">
                        {{ data.item.fullname }}
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title> {{ data.item.fullname }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ data.zip }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>

              </v-row>

              <v-row v-if="!isJudge">

                <v-col
                    cols="12"
                >
                  <ValidationProvider
                      name="Муниципалитет"
                      :rules="(isMunicipal) ? 'required' : ''"
                      v-slot="{ errors }"
                  >
                    <v-autocomplete
                        v-model="form.municipals.selected"
                        :items="form.municipals.list"
                        :search-input.sync="municipalSearchString"
                        label="Муниципалитет"
                        filled
                        :loading="form.municipals.loading"
                        item-value="id"
                        item-text="name"
                        no-filter
                        placeholder="Начните вводить текст"
                        return-object
                        hide-details
                        dense
                        :disabled="!isMunicipal || !form.regions.selected"
                        :error-messages="errors"
                        :readonly="loading"
                    >
                      <template v-slot:selection="data">
                        {{ data.item.type }} {{ data.item.name }}
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.type }} {{ data.item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ data.zip }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>

              </v-row>

              <v-row v-if="!isJudge">
                <v-col cols="12">
                  <v-checkbox
                      v-model="form.access_analytics"
                      :label="'Доступ к разделу аналитика'"
                      hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
          </v-card-text>


          <v-card-actions class="pa-4">
            <v-btn
                color="primary"
                :disabled="invalid || loading"
                @click="submit()"
                :loading="loading"
            >
              {{ buttonLabel }}
            </v-btn>

            <v-btn
                color="primary"
                outlined
                text
                @click="dialog = false"
                v-show="!loading"
            >
              Отменить
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate';

setInteractionMode('eager');
import debounce from 'debounce'

import {mapState, mapActions} from 'vuex';

import {api} from '@/api';

export default {
  name: 'create-edit-user',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    populateWith: {
      type: Object,
      default: () => ({})
    },
    regionsData: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    userSearchString: '',
    regionSearchString: '',
    municipalSearchString: '',
    form: {
      profile: {
        uin: null,
        name: null,
        surname: null,
        middlename: null,
      },
      email: null,
      role: null,
      regions: {
        list: [],
        selected: null,
        loading: false,
      },
      municipals: {
        list: [],
        selected: null,
        loading: false
      },
      access_analytics: null
    }
  }),
  watch: {
    municipalSearchString(value) {
      if (value) this.getMunicipals(value, this);
    },
    populateWith() {

      Object.assign(this.$data.form, this.$options.data().form)
      this.form.role = this.roles.regional_operator.name

      if (this.$gates.hasRole(this.roles.regional_operator.name) ||
          this.$gates.hasRole(this.roles.municipal_operator.name)
      ) {
        this.form.regions.selected = this.user.region;
        this.form.regions.list.push(this.user.region);

        if (this.$gates.hasRole(this.roles.regional_operator.name)){
          this.form.role = this.roles.municipal_operator.name;
        } else {
          this.form.role = this.roles.judge.name;
        }

      }

      if (this.isEdit) {

        this.form.regions.selected = this.populateWith.region;
        this.form.regions.list.push(this.populateWith.region);

        if (this.populateWith.municipality) {
          this.form.municipals.selected = this.populateWith.municipality;
          this.form.municipals.list.push(this.populateWith.municipality);
        }

        this.form.role = this.populateWith.role.name;
        this.form.access_analytics = this.populateWith.access_analytics;
        this.form.profile.uin = this.populateWith.profile.id;
        this.form.profile.name = this.populateWith.profile.name;
        this.form.profile.surname = this.populateWith.profile.surname;
        this.form.profile.middlename = this.populateWith.profile.middlename;

        this.form.email = this.populateWith.email;
      }
    },

  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('roles', ['roles']),
    isRegional() {
      return this.form.role === this.roles.regional_operator.name;
    },
    isMunicipal() {
      return this.form.role === this.roles.municipal_operator.name;
    },
    isJudge() {
      return  this.form.role === this.roles.judge.name;
    },
    isEdit() {
      return (this.populateWith && Object.keys(this.populateWith).length > 0 && this.populateWith.constructor === Object)
    },
    title() {
      return this.isEdit ? 'Редактировать пользователя' : 'Добавить пользователя'
    },
    buttonLabel() {
      return this.isEdit ? 'Сохранить' : 'Добавить'
    }
  },
  methods: {
    ...mapActions('app', ['notify',  'error']),
    getMunicipals: debounce((value, self) => {
      self.form.municipals.loading = true;
      api.kladr.get({query: value, contentType: 'city', regionId: self.form.regions.selected.id})
          .then((response) => {
            self.form.municipals.list = response.data.list;
            self.form.municipals.loading = false;
          })
    }, 500),
    removeMunicipality() {
      this.form.municipals.selected = null
      this.form.municipals.list = []
    },
    submit() {
      this.$refs.observer.validate().then(async (result) => {
        if (result) {
          this.loading = true;

          let data = {
            profile: this.form.profile,
            role: this.form.role,
            region: this.form.regions.selected,
            municipality: this.form.municipals.selected,
            email: this.form.email,
            access_analytics: this.form.access_analytics,
          }

          try {
            if (this.isEdit) {
              await api.user.update(this.populateWith.id, data);
            } else {
              await api.user.store(data);
            }

            this.dialog = false;

            this.$emit('submit-success');

            this.notify('Пользователь был успешно ' + ((this.isEdit) ? 'изменен' : 'добавлен'))

          } catch (err) {
              if (err.response.data.error === "The profile.uin has already been taken.") {
                this.error("Профиль с таким УИН уже существует");
              }

              if (err.response.data.error === "The email has already been taken.") {
                this.error("Профиль с таким email уже существует");
              }
          }

          this.loading = false;
        }
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.disabled-autocomplete {
  pointer-events: none;
  background-color: #f5f5f5;

  ::v-deep {
    .v-input__slot {
      background-color: #f5f5f5;
    }

    .v-input__slot label {
      color: #999;
    }

    .v-input__slot .v-select__selections {
      color: #999;
    }

    .v-input__slot .v-select__selection--comma {
      color: #999;
    }
  }
}
</style>
