<template>

  <v-dialog v-model="show"  persistent max-width="600px">
    <v-card>
      <v-card-title>
        Изменить изображение
      </v-card-title>

      <v-card-text>
        <cropper
            :src="src"
            ref="cropper"
            :stencil-props="this.settings"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="crop">Применить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'image-cropper-component',
  components: {
    Cropper,
  },
  props:{
    src: {
      type: String,
      default: null
    },
    settings: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    crop() {
      const { coordinates, canvas, } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      if (canvas) {
        canvas.toBlob(blob => {
          this.$emit('cropped', blob);
        }, 'image/jpeg')
      }
    },
  }
}
</script>

<style scoped>

</style>