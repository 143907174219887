import {httpClient} from '@/modules/httpClient.js';

const multipart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const all = async (data = {}) => httpClient.get('/festivals', {params: data});
export const get = async (id, data = {}) => httpClient.get(`/festivals/${id}`, data);
export const store = async (data = {}) => httpClient.post('/festivals', data);
export const update = async (id, data = {}) => httpClient.patch(`/festivals/${id}`, data);
export const remove = async (id, data = {}) => httpClient.delete(`/festivals/${id}`, data);
export const archive = async (id, data = {}) => httpClient.post(`/festivals/${id}/archive`, data);

export default {
  all,
  get,
  store,
  update,
  delete: remove,
  archive,

  /**
   * Получаем свежие статусы фестивалей.
   *
   * @param   {Array.<number>} festivalIds
   * @returns {Promise<*>}
   */
  getStatuses: async (festivalIds) => {
    return httpClient.get(`/festivals/protocols/generation_statuses?festival_ids=${festivalIds.join(',')}`)
  },

  /**
   * Эстафета.
   */
  relayRace: {
    all: async (festivalId, data = {}) => httpClient.get(`/festivals/${festivalId}/relays`, {params: data}),
    update: async (festivalId, relayId, data = {}) => httpClient.post(`/festivals/${festivalId}/relays/${relayId}`, data),
  },

  exercises: {
    all: async (id, data = {}) => httpClient.get(`/festivals/${id}/exercises`, {params: data}),
    update: async (id, data = {}) => httpClient.patch(`/festivals/${id}/exercises`, data)
  },
  teams: {
    all: async (id, data = {}) => httpClient.get(`/festivals/${id}/teams`, {params: data}),
    store: async (id, data = {}) => httpClient.post(`/festivals/${id}/teams`, data),
    update: async (id, team, data = {}) => httpClient.patch(`/festivals/${id}/teams/${team}`, data),
    delete: async (id, data = {}) => httpClient.delete(`/festivals/${id}/teams`, {data: data})
  },
  members: {
    all: async (id, data = {}) => httpClient.get(`/festivals/${id}/members`, {params: data}),
    store: async (id, data = {}) => httpClient.post(`/festivals/${id}/members`, data),
    'export': async (id, data = {}) => httpClient.get(`/festivals/${id}/members/export`, data),
    'import': async (id, exercise, data = {}) => httpClient.post(`/festivals/${id}/members/import`, data, multipart),
    'importStatus': async (id, data = {}) => httpClient.get(`/festivals/${id}/members/import`, {params: data}),
    update: async (id, member, data = {}) => httpClient.patch(`/festivals/${id}/members/${member}`, data),
    delete: async (id, data = {}) => httpClient.delete(`/festivals/${id}/members`, {data: data}),
    avatar: async (id, member, data = {}) => httpClient.post(`/festivals/${id}/members/${member}/avatar`, data, multipart),
    badges: async (id, data = {}) => httpClient.post(`/festivals/${id}/members/badges`, data),
    badgesStatus: async (id, data = {}) => httpClient.get(`/festivals/${id}/members/badges/status`, {params: data}),

  },
  interface: {
    get: async (id, data = {}) => httpClient.get(`/festivals/${id}/interface`, {params: data}),
    update: async (id, data = {}) => httpClient.patch(`/festivals/${id}/interface`, data),
  },
  judges: {
    all: async (id, data = {}) => httpClient.get(`/festivals/${id}/judges`, {params: data}),
    search: async (id, data = {}) => httpClient.get(`/festivals/${id}/judges/search`, {params: {...data}}),
    store: async (id, data = {}) => httpClient.post(`/festivals/${id}/judges`, data),
    update: async (id, judge, data = {}) => httpClient.patch(`/festivals/${id}/judges/${judge}`, data),
    delete: async (id, judge = {}) => httpClient.delete(`/festivals/${id}/judges/${judge}`),
    deleteAll: (id, data) => httpClient.delete(`/festivals/${id}/judges/mass_destroy`, {data})
  },
  protocols: {
    all: async (id, data = {}) => httpClient.get(`/festivals/${id}/exercises/grouped`, {params: data}),
    get: async (id, exercise, data = {}) => httpClient.get(`/festivals/${id}/exercises/${exercise}/protocols`, {params: data}),
    update: async (id, exercise, protocol, data = {}) => httpClient.patch(`/festivals/${id}/exercises/${exercise}/protocols/${protocol}`, data),
    template: {
      download: async (id, exercise) => httpClient.get(`/festivals/${id}/exercises/${exercise}/protocols/template/download`),
      upload: async (id, exercise, data = {}) => httpClient.post(`/festivals/${id}/exercises/${exercise}/protocols/template/upload`, data, multipart),
      status: async (id, exercise, data = {}) => httpClient.get(`/festivals/${id}/exercises/${exercise}/protocols/template/upload`, {params: data})
    },
    generations: {
      list: async () => httpClient.get('/festivals/protocols/generations'),
      mark: async (id) => httpClient.post(`/festivals/protocols/generations/${id}/mark`)
    },
    export: {
      get: async (id) => httpClient.get(`/festivals/${id}/protocols/export`),
      status: {
        get: async (id, data = {}) => httpClient.get(`/festivals/${id}/protocols/export/status`, {params: data})
      }
    }
  },
  tournamentTable: {
    get: async (id, data = {}) => httpClient.get(`/festivals/${id}/tournament-table`, {params: data}),
    exportToXlsx: async (id, data = {}) => httpClient.get(`/festivals/${id}/tournament-table/export/xlsx`, {params: data}),
  },
  screens: {
    all: async (id, data = {}) => httpClient.get(`/festivals/${id}/screens`, {params: data}),
    store: async (id, data = {}) => httpClient.post(`/festivals/${id}/screens`, data),
    update: async (id, screen, data = {}) => httpClient.post(`/festivals/${id}/screens/${screen}`, data),
    delete: async (id, screen) => httpClient.delete(`/festivals/${id}/screens/${screen}`),
  },
}
