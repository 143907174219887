import {roles} from '@/constants/roles'

const namespaced = true;

export default {
    namespaced,
    state: {
        roles: roles
    },
    actions: {
    },
    getters: {
        rolesArray: state => {
            return Object.values(state.roles);
        },
        filteredRoles: state => ({$gates}) => {
            return Object.values(state.roles).filter(({name}) => { return $gates.hasPermission(`users.view.role.${name}`)})
        }
    },
    mutations: {
    }
};
