var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('p',{staticClass:"font-weight-medium mb-0"},[_vm._v("Новый СВОД загружен")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Название","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Начало действия","prepend-icon":"mdi-calendar","filled":"","readonly":"","dense":"","hide-details":"","disabled":_vm.form.loading},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"error-messages":errors,"disabled":_vm.form.loading,"min":_vm.tomorrow,"allowed-dates":_vm.allowedDates},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.form.loading,"loading":_vm.form.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Применить ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }