<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="520"
    >
      <v-card>
        <v-card-title class="word-break">
          {{ title }}
        </v-card-title>
        <v-card-text v-for="(line, index) in description" :key="index" class="pb-1">{{ line }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              outlined
              text
              @click="dialog = false"
              v-show="!loading"
          >
            Нет
          </v-btn>
          <v-btn
              color="primary"
              :disabled="loading"
              :loading="loading"
              @click="submit"
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {api} from '@/api';
import {mapActions} from 'vuex';

export default {
  name: 'remove-item',
  props: {
    populateWith: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: false,
    dialog: false
  }),
  computed: {
    title() {
      let type = '';

      switch (this.type) {
        case 'user':
          type = 'отключить пользователя'
          break;
        case 'template':
          type = 'удалить шаблон'
          break;
        case 'svod':
          type = 'удалить свод'
          break;
        case 'judge':
          type = 'удалить судей'
          break;
        case 'festival':
          type = 'удалить фестиваль'
          break;
        case 'festivalMembers':
          type = 'удалить участников'
          break;
        case 'festivalTeams':
          type = 'удалить команды'
          break;
        case 'screen':
          type = 'удалить ссылку на испытание'
          break;
        case 'exercise':
          type = 'удалить испытание'
          break;
      }

      return `Вы действительно хотите ${type}?`
    },
    description(){
      let description = [];
      switch (this.type){
        case 'user':
          description.push(this.populateWith && this.populateWith.profile ? this.populateWith.profile.fullname : '')
          description.push(this.populateWith && this.populateWith.profile ? `УИН: ${this.populateWith.profile.id}` : '')
          description.push(this.populateWith && this.populateWith.role ? `Роль: ${this.populateWith.role.title}` : '')
              return description
        case 'judge':
          description.push(this.populateWith && this.populateWith.profile ? this.populateWith.profile.fullname : '')
          description.push(this.populateWith && this.populateWith.profile ? `УИН: ${this.populateWith.profile.id}` : '')
          return description
        case 'festival':
          description.push('Все сведения о данном фестивале будут удалены')
          return description
        default:
          return description
      }
    }
  },
  methods: {
    ...mapActions('app', ['error']),
    async submit(){
      this.loading = true;
      try {
        switch (this.type){
          case 'user':
            await api.user.delete(this.populateWith.id)
            break
          case 'template':
            await api.template.delete(this.populateWith.id)
            break
          case 'svod':
            await api.svod.delete(this.populateWith.id)
            break
          case 'judge':
            await api.festival.judges.deleteAll(this.populateWith.festival.id, {judges_ids: this.populateWith.judges.map(x => x.id)})
            break;
          case 'festivalMembers':
            await api.festival.members.delete(this.populateWith.festival.id, this.populateWith)
            break
          case 'festivalTeams':
            await api.festival.teams.delete(this.$route.params.id, {teams_id: this.populateWith.map(x => x.id)});
            break;
          case 'festival':
            await api.festival.delete(this.populateWith.id);
            break;
          case 'screen':
            await api.festival.screens.delete(this.populateWith.festival.id, this.populateWith.id);
            break;
          case 'exercise':
            await api.exercise.delete(this.populateWith.id);
            break;
        }

        this.dialog = false;
        this.$emit('remove-item-success', this.populateWith);
      } catch (e) {
        this.error(e)
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.word-break {
  word-break: break-word;
}
</style>
