<template>
  <VSelect
    label="Выберите значение"
    item-value="id"
    item-text="name"
    solo
    outlined
    dense
    hide-details
    flat
    :items="items"
    :value="value"
    :loading="busy"
    :disabled="busy"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { FESTIVAL_PROTOCOL_MEMBER_STATUSES } from '@/constants/festival'

export default {
  props: {
    value: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(FESTIVAL_PROTOCOL_MEMBER_STATUSES).includes(value)
      }
    },
    busy: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    items() {
      return Object.entries(FESTIVAL_PROTOCOL_MEMBER_STATUSES)
        .map(([ key, value ]) => ({ id: key, name: value }))
    }
  }
}
</script>
