var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"table",staticClass:"table",attrs:{"headers":_vm.table.headers,"items":_vm.table.list,"item-key":"id","loading":_vm.form.loading,"server-items-length":_vm.table.meta.total,"footer-props":{
            itemsPerPageOptions : [100]
          },"hide-default-footer":""},on:{"click:row":_vm.saveItem},scopedSlots:_vm._u([{key:"item.exercise_id",fn:function(ref){
          var item = ref.item;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":item.exercises_list,"outlined":"","item-text":"title_ru","item-value":"id","placeholder":"Выбрать","hide-details":"","dense":"","error-messages":errors,"disabled":_vm.disabledInputs},on:{"change":function($event){return _vm.changeSelect(item)}},model:{value:(item.exercise_id),callback:function ($$v) {_vm.$set(item, "exercise_id", $$v)},expression:"item.exercise_id"}})]}}],null,true)}),(item.notInCurrentVersion)?_c('v-alert',{staticClass:"mt-2",attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" Этого испытания нет в используемом СВОДе ")]):_vm._e(),(item.duplicate)?_c('v-alert',{staticClass:"mt-2",attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" Дубликат испытания ")]):_vm._e()]}},{key:"item.gender_id",fn:function(ref){
          var item = ref.item;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":item.genders_list,"outlined":"","item-text":"name_ru","item-value":"id","placeholder":"Выбрать","dense":"","hide-details":"","multiple":"","error-messages":errors,"disabled":_vm.disabledInputs},on:{"change":function($event){return _vm.changeSelect(item)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.selectAllGenders(item)}}},[_c('v-list-item-action',[(_vm.checkedAllAvailableGenders(item))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Для всех ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,true),model:{value:(item.gender_ids),callback:function ($$v) {_vm.$set(item, "gender_ids", $$v)},expression:"item.gender_ids"}})]}}],null,true)})]}},{key:"item.age_category_id",fn:function(ref){
          var item = ref.item;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":item.age_categories_list,"outlined":"","item-text":"description","item-value":"id","placeholder":"Выбрать","multiple":"","dense":"","hide-details":"","error-messages":errors,"disabled":_vm.disabledInputs},on:{"change":function($event){return _vm.changeSelect(item)}},scopedSlots:_vm._u([{key:"selection",fn:function(props){return [(props.index < 4)?_c('span',[_vm._v(" "+_vm._s(props.item.name)+_vm._s((item.age_category_ids.length - 1 > props.index) ? ',' : '')+" ")]):_vm._e(),(props.index === 4)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" + еще "+_vm._s(item.age_category_ids.length - 1)+" ")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.selectAllAgeCategories(item)}}},[_c('v-list-item-action',[(_vm.checkedAllAvailableCategories(item))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Для всех ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,true),model:{value:(item.age_category_ids),callback:function ($$v) {_vm.$set(item, "age_category_ids", $$v)},expression:"item.age_category_ids"}})]}}],null,true)})]}},{key:"item.priority",fn:function(ref){
          var item = ref.item;
return [_c('ValidationProvider',{attrs:{"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","hide-details":"","type":"number","error-messages":errors,"disabled":_vm.disabledInputs,"outlined":""},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})]}}],null,true)})]}},(!_vm.only_show)?{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"table-actions"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [(_vm.canDelete())?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}],null,true)},[_vm._v(" Удалить ")])],1)]}}:null],null,true)}),(!_vm.only_show)?_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","text":"","disabled":_vm.form_loading},on:{"click":_vm.addExercise}},[_vm._v(" Добавить испытание ")])],1)],1):_vm._e(),(_vm.is_festival)?_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" По испытанию есть заполненные протоколы ")]),_c('v-card-text',{staticClass:"pb-0"},[_vm._v("Заполненые протоколы могут быть удалены")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmChanges()}}},[_vm._v(" Продолжить ")]),_c('v-btn',{attrs:{"color":"primary","text":"","outlined":""},on:{"click":function($event){return _vm.cancelChanges()}}},[_vm._v(" Отменить ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }