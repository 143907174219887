<template>
  <div>
    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" lg="4" class="mb-3 mb-lg-0">
        <v-btn
            color="primary"
            link
            to="/festivals/create"
            v-permission="'festivals.create'"
        >
          Добавить фестиваль
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="d-flex flex-lg-row-reverse mr-0 mr-lg-5">
          <v-checkbox
              v-model="form.onlyMine"
              :label="'Только мои фестивали'"
              class="mt-0 mb-3 mb-lg-0 mt-lg-1 d-flex"
              hide-details
              v-permission="'festivals.create'"
              @change="fetch()"
          ></v-checkbox>
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск по региону или названию фестиваля"
            dense
            @keyup="search"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            :hide-default-header="isMobile"
            @update:options="optionsUpdated"
        >
          <template v-slot:item="{item}">
            <tr v-if="isDesktop">
              <td>
                <FestivalStatusLabel
                  :status="item.final_table_protocol_generation_status"
                />
              </td>
              <td>
                {{ item.id }}
                <v-tooltip top content-class="tooltip-gto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        right
                        color="orange"
                        v-if="item.hasUnacceptableExercises"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Фестиваль содержит испытания не входящие в СВОД</span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip top content-class="tooltip-gto">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 130px;" v-bind="attrs"
                         v-on="on">
                      {{ item.title_ru ? item.title_ru : item.title_en }}
                    </div>
                  </template>
                  <span>{{ item.title_ru ? item.title_ru : item.title_en }}</span>
                </v-tooltip>
              </td>
              <td>
                {{ item.location.region ? item.location.region.fullname : 'Не РФ' }}
              </td>
              <td >
                <v-tooltip left content-class="tooltip-gto">
                  <template #activator="{on, attrs}">
                    <v-icon v-bind="attrs" v-on="on"  @click="goToFest(item)" class="mr-3">mdi-open-in-new</v-icon>
                  </template>
                  Открыть страницу фестиваля
                </v-tooltip>
                <v-tooltip right content-class="tooltip-gto">
                  <template #activator="{on, attrs}">
                    <v-icon v-bind="attrs" v-on="on" @click="copyLink(item)">mdi-content-copy</v-icon>
                  </template>
                  Скопировать ссылку на страницу фестиваля
                </v-tooltip>
              </td>
              <td>
                {{ item.opening_date|formatDate }} - {{ item.ending_date|formatDate }}
              </td>
              <td>
                <div class="table-actions d-flex">
                  <v-tooltip left content-class="tooltip-gto">
                    <template #activator="{on, attrs}">
                      <v-icon
                          class="mr-2"
                          v-if="canEdit(item)"
                          v-on="on"
                          v-bind="attrs"
                          @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    Редактировать
                  </v-tooltip>
                  <v-tooltip left content-class="tooltip-gto">
                    <template #activator="{on, attrs}">
                      <v-icon
                          class="mr-2"
                          v-if="canShow(item)"
                          v-on="on"
                          v-bind="attrs"
                          @click="showItem(item)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    Просмотреть
                  </v-tooltip>
                  <v-tooltip left content-class="tooltip-gto">
                    <template #activator="{on, attrs}">
                      <v-icon
                          class="mr-2"
                          v-if="canDelete(item)"
                          v-on="on"
                          v-bind="attrs"
                          @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    Удалить
                  </v-tooltip>
                  <v-tooltip left content-class="tooltip-gto">
                    <template #activator="{on, attrs}">
                      <v-icon
                          v-if="canArchive(item)"
                          v-on="on"
                          v-bind="attrs"
                          @click="archiveItem(item)"
                      >
                        mdi-archive-arrow-up
                      </v-icon>
                    </template>
                    Архивировать
                  </v-tooltip>
                </div>
              </td>
            </tr>


            <tr class="d-flex" v-if="isMobile">
              <v-row no-gutters class="pa-3 pt-0">
                <v-col cols="1">
                  <FestivalStatusLabel
                    :status="item.final_table_protocol_generation_status"
                  />
                </v-col>
                <v-col cols="1">
                  {{ item.id }}
                </v-col>
                <v-col cols="11">
                  <p class="mb-0">{{ item.title_ru ? item.title_ru : item.title_en }}</p>
                  <p class="mb-0 text-caption">{{ item.opening_date|formatDate }} -
                    {{ item.ending_date|formatDate }}</p>
                  <div class="d-flex justify-space-between mt-2">
                    <div>
                      <v-icon @click="goToFest(item)" class="mr-3">mdi-open-in-new</v-icon>
                      <v-icon @click="copyLink(item)">mdi-content-copy</v-icon>
                    </div>
                    <div class="d-flex">
                      <v-icon
                          class="mr-2"
                          v-if="canEdit(item)"
                          @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                          class="mr-2"
                          v-if="canShow(item)"
                          @click="showItem(item)"
                      >
                        mdi-eye
                      </v-icon>
                      <v-icon
                          v-if="canDelete(item)"
                          @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                          v-if="canArchive(item)"
                          @click="archiveItem(item)"
                      >
                        mdi-archive-arrow-up
                      </v-icon>
                    </div>
                  </div>
                </v-col>
                <v-divider></v-divider>
              </v-row>

            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
        v-model="archiveDialog.show"
        persistent
        max-width="520"
    >
      <v-card>
        <v-card-title>
          Архивировать фестиваль
        </v-card-title>
        <v-card-text class="pb-1">
          Из фестиваля будут удалены все личные данные участников, и он станет недоступен для просмотра. Напоминаем, что
          фестиваль автоматически архивируется через 2 месяца после завершения
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              outlined
              text
              @click="archiveDialog.show = false"
              v-show="!archiveDialog.loading"
          >
            Нет
          </v-btn>
          <v-btn
              color="primary"
              :disabled="archiveDialog.loading"
              :loading="archiveDialog.loading"
              @click="archiveFestival()"
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <remove-item ref="remover" :populate-with="selected" :type="'festival'"
                 @remove-item-success="removed"></remove-item>
  </div>
</template>
<script>
import debounce from 'debounce'

import {mapState, mapActions, mapGetters} from 'vuex';
import {api} from '@/api';
import permissions from '@/constants/permisions';
import RemoveItem from '@/components/modals/remove-item';
import FestivalStatusLabel from '@/views/Festival/FestivalStatusLabel.vue'
import { FESTIVAL_STATUS_UPDATE_INTERVAL } from "@/constants/festival";

export default {
  name: 'FestivalIndex',
  components: {
    RemoveItem,
    FestivalStatusLabel
  },
  data() {
    return {
      form: {
        search: null,
        onlyMine: false,
        loading: false,
        role: {
          selected: 'all'
        }
      },
      table: {
        headers: [
          {
            text: 'Статус',
            align: 'start',
            value: 'final_table_protocol_generation_status',
          },
          {
            text: 'ID',
            value: 'id',
          },
          {text: 'Название', value: 'title'},
          {text: 'Регион', value: 'region'},
          {text: 'Ссылка', value: 'link', sortable: false},
          {text: 'Проведение', value: 'dates'},
          {text: '', value: 'actions', sortable: false, width: '10%'},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      selected: null,
      archiveDialog: {
        show: false,
        loading: false
      }
    }
  },
  mounted() {
    setInterval(this.getStatuses, FESTIVAL_STATUS_UPDATE_INTERVAL)
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('app', ['isMobile', 'isDesktop']),
    domain() {
      return process.env.VUE_APP_FRONT_URL
    },
  },
  methods: {
    ...mapActions('app', ['notify']),

    /**
     * Получаем свежие статусы фестивалей
     * и обновляем текущие.
     *
     * @returns {Promise<void>}
     */
    async getStatuses() {
      const festivalIds = this.table.list
        .map((item) => item.id)

      const { data } = await api.festival.getStatuses(festivalIds)

      data.forEach((newFestival) => {
        this.table.list.forEach((festival) => {
          if (+newFestival.id === +festival.id) {
            festival.final_table_protocol_generation_status = newFestival.final_table_protocol_generation_status
          }
        })
      })
    },

    async fetch() {
      try {
        this.form.loading = true;
        let response = await api.festival.all({
          search: this.form.search,
          only_mine: this.form.onlyMine,
          role: this.form.role.selected,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.table.list = response.data.list
        this.table.meta = response.data.meta
        this.form.loading = false;
      } catch (e) {
        console.error(e);
      }
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated() {
      this.fetch()
    },
    editItem(item) {
      this.$router.push({name: 'festivals.edit', params: {id: item.id}})
    },
    deleteItem(item) {
      this.selected = item;
      this.$refs.remover.dialog = true;
    },
    canEdit(item) {
      return permissions.actions.editFestival(this, item);
    },
    canDelete(item) {
      return permissions.actions.deleteFestival(this, item);
    },
    canShow(item) {
      return permissions.actions.showFestival(this, item);
    },
    canArchive(item) {
      const endingDate = new Date(`${item.ending_date}T00:00:00.000`)
      endingDate.setDate(endingDate.getDate() + 1)
      const currentDate = new Date()
      currentDate.setHours(0,0,0,0)

      return currentDate >= endingDate && permissions.actions.archiveFestival(this, item);
    },
    showItem(item) {
      if (this.$gates.hasRole('judge')) {
        this.$router.push({name: 'festivals.protocols.index', params: {id: item.id}})
      } else {
        this.$router.push({name: 'festivals.tournament-table.index', params: {id: item.id}})
      }
    },
    removed() {
      this.fetch();
      this.notify('Фестиваль удален')
    },
    archiveItem(item) {
      this.selected = item;
      this.archiveDialog.show = true;
    },
    async archiveFestival() {
      this.archiveDialog.loading = true;
      try {
        await api.festival.archive(this.selected.id);
        this.archiveDialog.show = false;
        this.notify('Фестиваль архивирован')
        await this.fetch();
      } catch (e) {
        console.error(e);
      }
      this.archiveDialog.loading = false;
    },
    goToFest(item) {
      window.open(item.link, '_blank').focus();
    },
    copyLink(item) {
      const el = document.createElement('textarea');
      el.value = item.link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.notify('Ссылка скопирована')
    }
  },
}
</script>

<style>
.tooltip-gto {
  background: #fff;
  color: #000000;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;
  max-width: 400px;
  opacity: 1 !important;
}
</style>
