<template>
  <div>
    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" md="4" class="mb-3 mb-md-0">
        <v-btn
            color="primary"
            link
            to="/templates/create"
            v-permission="'templates.create'"
        >
          Создать шаблон
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex flex-md-row-reverse mr-0 mr-md-5">
          <v-checkbox
              v-model="form.onlyMine"
              :label="'Только мои шаблоны'"
              class="mt-0 mb-3 mb-md-0 mt-md-1 d-flex"
              hide-details
              @change="fetch()"
          ></v-checkbox>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск по названию шаблона"
            dense
            @keyup="search"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template v-slot:item.region="{ item }">
            {{ item.region ? item.region.fullname : '-'}}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="table-actions d-flex">
              <v-tooltip left content-class="tooltip-gto">
                <template #activator="{on, attrs}">
                  <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      v-if="canEdit(item)"
                      @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                Редактировать
              </v-tooltip>
              <v-tooltip left content-class="tooltip-gto">
                <template #activator="{on, attrs}">
                  <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      v-if="canShow(item)"
                      @click="showItem(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                Просмотреть
              </v-tooltip>
              <v-tooltip left content-class="tooltip-gto">
                <template #activator="{on, attrs}">
                  <v-icon
                      v-if="canDelete(item)"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                Удалить
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <remove-item ref="remover" :populate-with="selected" :type="'template'" @remove-item-success="removed"></remove-item>
  </div>
</template>
<script>
import debounce from 'debounce'

import {mapState, mapActions} from 'vuex';
import {api} from '@/api';
import permissions from '@/constants/permisions';
import RemoveItem from '@/components/modals/remove-item';

export default {
  name: 'TemplateIndex',
  components: {
    RemoveItem
  },
  data() {
    return {
      form: {
        search: null,
        onlyMine: false,
        loading: false,
        role: {
          selected: 'all'
        }
      },
      table: {
        headers: [
          {
            text: 'ID',
            align: 'start',
            value: 'id',
          },
          {text: 'Название', value: 'name'},
          {text: 'М исп', value: 'male_exercises_count', sortable: false},
          {text: 'Ж исп', value: 'female_exercises_count', sortable: false},
          {text: 'Автор', value: 'user.profile.fullname', sortable: false},
          {text: 'Регион', value: 'region', sortable: false},
          {text: 'Дата создания', value: 'created_at', sortable: false},
          {text: 'Дата изменения', value: 'updated_at', sortable: false},
          {text: '', value: 'actions', sortable: false},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      selected: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify']),

    async fetch() {
      this.form.loading = true;
      try {
        let response = await api.template.all( {
          search: this.form.search,
          only_mine: this.form.onlyMine,
          role: this.form.role.selected,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.table.list = response.data.list
        this.table.meta = response.data.meta
        this.form.loading = false;
      } catch (e) {
        console.error(e);
      }
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated(){
      this.fetch()
    },
    editItem(item){
      this.$router.push({ name: 'templates.edit', params: { id: item.id } })
    },
    deleteItem(item){
      this.selected = item;
      this.$refs.remover.dialog = true;
    },
    canEdit(item){
      return permissions.actions.editTemplate(this, item);
    },
    canDelete(item){
      return permissions.actions.deleteTemplate(this, item);
    },
    canShow(item){
      return permissions.actions.showTemplate(this, item);
    },
    showItem(item){
      this.$router.push({ name: 'templates.show', params: { id: item.id } })
    },
    removed(){
      this.fetch();
      this.notify('Шаблон удален')
    }
  },
}
</script>

<style scoped>

</style>
