var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.timeInputMask),expression:"timeInputMask"}],staticClass:"input-width",attrs:{"hide-details":"","solo":"","dense":"","placeholder":_vm.timePlaceholder,"loading":item.loading},on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}})]}},{key:"item.penalty_time",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.penaltyTimeInputMask),expression:"penaltyTimeInputMask"}],staticClass:"input-width",attrs:{"hide-details":"","solo":"","dense":"","placeholder":_vm.penaltyTimePlaceholder,"loading":item.loading},on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.penalty_time),callback:function ($$v) {_vm.$set(item, "penalty_time", $$v)},expression:"item.penalty_time"}})]}},{key:"item.points_all",fn:function(ref){
var item = ref.item;
return [(! _vm.automaticCalculation)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.pointsInputMask),expression:"pointsInputMask"}],staticClass:"input-width",attrs:{"hide-details":"","solo":"","dense":"","loading":item.loading},on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.points_all),callback:function ($$v) {_vm.$set(item, "points_all", $$v)},expression:"item.points_all"}}):[_vm._v(_vm._s(item.points_all))]]}},{key:"item.rating_all",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.points_all > 0 ? item.rating_all : '-')+" ")]}},{key:"item.incomplete_team",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.incomplete_team),callback:function ($$v) {_vm.$set(item, "incomplete_team", $$v)},expression:"item.incomplete_team"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }