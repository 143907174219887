import store from '@/store';

export function guard(to, from, next){
    if (!store.getters['auth/isLoggedIn'] || !store._vm.$gates){
        next(`/auth/login?redirect_url=${to.path}`);
        return;
    }

    if (!store._vm.$gates.hasPermission(to.name)){
        next('/festivals');
        return;
    }

    next();
}

