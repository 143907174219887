import {httpClient} from '@/modules/httpClient.js';

export const login = data => httpClient.post('/auth/login', data);
export const logout = () => httpClient.post('/auth/logout');

export const passwordReset = data => httpClient.post('/auth/passwords/reset', data)
export const restorePassword = data => httpClient.post('/auth/passwords/restore', data)

export default {
    login,
    logout,
    passwordReset,
    restorePassword
}
