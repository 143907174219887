var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('p',{staticClass:"subtitle-2 mb-0"},[_vm._v("Укажите желамые параметры, ссылка будет сгенерирована автоматически")])]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","label":"Название","hide-details":"","error-messages":errors,"disabled":_vm.loading,"dense":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label "},[_vm._v("Тип зачета")]),_c('v-radio-group',{attrs:{"error-messages":errors,"hide-details":""},model:{value:(_vm.form.credit_type),callback:function ($$v) {_vm.$set(_vm.form, "credit_type", $$v)},expression:"form.credit_type"}},[_c('v-radio',{attrs:{"label":"Личный","value":"personal"}}),_c('v-radio',{attrs:{"disabled":_vm.festival.settings.credit_type === 'personal',"label":"Командный и личный","value":"team"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Тип рейтинга")]),_c('v-radio-group',{attrs:{"error-messages":errors,"hide-details":""},model:{value:(_vm.form.rating),callback:function ($$v) {_vm.$set(_vm.form, "rating", $$v)},expression:"form.rating"}},[_c('v-radio',{attrs:{"label":"Суммарный","value":"summary"}}),_c('v-radio',{attrs:{"label":"Cредний","value":"average"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Пол")]),_c('v-checkbox',{attrs:{"label":"Мужской","value":1,"hide-details":"","error-messages":errors},model:{value:(_vm.form.genders),callback:function ($$v) {_vm.$set(_vm.form, "genders", $$v)},expression:"form.genders"}}),_c('v-checkbox',{staticClass:"mt-1",attrs:{"label":"Женский","value":2,"hide-details":"","error-messages":errors},model:{value:(_vm.form.genders),callback:function ($$v) {_vm.$set(_vm.form, "genders", $$v)},expression:"form.genders"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filled":"","items":_vm.exercises,"label":"Испытания","error-messages":errors,"item-value":"id","item-text":"text","multiple":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggleSelector('exercises')}}},[_c('v-list-item-action',[(_vm.form.exercises.length === _vm.exercises.length)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Все испытания ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.form.exercises.length === _vm.exercises.length && index < 1)?_c('span',[_vm._v("Все испытания")]):(_vm.form.exercises.length !== _vm.exercises.length)?_c('span',[(index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" + еще "+_vm._s(_vm.form.exercises.length - 1))]):_vm._e()]):_vm._e()]}}],null,true),model:{value:(_vm.form.exercises),callback:function ($$v) {_vm.$set(_vm.form, "exercises", $$v)},expression:"form.exercises"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":{ required: _vm.festival.settings.credit_type !== 'personal'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filled":"","items":_vm.teams,"label":"Команды","error-messages":errors,"item-value":"id","item-text":"text","multiple":"","dense":"","hide-details":"","disabled":_vm.festival.settings.credit_type === 'personal'},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggleSelector('teams')}}},[_c('v-list-item-action',[(_vm.form.teams.length === _vm.teams.length)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Все команды ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.form.teams.length === _vm.teams.length && index < 1)?_c('span',[_vm._v("Все команды")]):(_vm.form.teams.length !== _vm.teams.length)?_c('span',[(index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" + еще "+_vm._s(_vm.form.teams.length - 1))]):_vm._e()]):_vm._e()]}}],null,true),model:{value:(_vm.form.teams),callback:function ($$v) {_vm.$set(_vm.form, "teams", $$v)},expression:"form.teams"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filled":"","items":_vm.age_categories,"label":"Возрастные группы","error-messages":errors,"item-value":"id","item-text":"text","multiple":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggleSelector('age_categories')}}},[_c('v-list-item-action',[(_vm.form.age_categories.length === _vm.age_categories.length)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Все категории ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.form.age_categories.length === _vm.age_categories.length && index < 1)?_c('span',[_vm._v("Все категории")]):(_vm.form.age_categories.length !== _vm.age_categories.length)?_c('span',[(index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" + еще "+_vm._s(_vm.form.age_categories.length - 1))]):_vm._e()]):_vm._e()]}}],null,true),model:{value:(_vm.form.age_categories),callback:function ($$v) {_vm.$set(_vm.form, "age_categories", $$v)},expression:"form.age_categories"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filled":"","items":_vm.display_members_count,"label":"Количество участников на экране","error-messages":errors,"item-value":"id","item-text":"text","dense":"","hide-details":""},model:{value:(_vm.form.display_members_count),callback:function ($$v) {_vm.$set(_vm.form, "display_members_count", $$v)},expression:"form.display_members_count"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"color":"primary","filled":"","label":"Ссылка","hide-details":"","disabled":true,"dense":""},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Отменить ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }