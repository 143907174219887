<template>
  <div>
    <festival-tabs-component :festival="festival"></festival-tabs-component>

    <div v-show="page.loading" class="text-center pa-10">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
    <div v-show="!page.loading">
      <div v-show="canCreateTeams">
        <v-row class="pa-3 pb-0">
          <v-col cols="12" md="3" class="pb-0">
            <v-btn color="primary" class="mr-6 fw" @click="openCreateDialog()">
              Добавить команду
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" class="pb-0">
            <label class="caption line">Категория команд:</label>
            <p class="caption mb-0"><strong>{{ teamCategoryLabel }}</strong></p>
          </v-col>
          <v-col cols="12" md="2" class="pb-0">
            <v-btn color="primary"
                   :disabled="table.selected.length === 0"
                   class="fw"
                   text
                   outlined
                   @click="deleteItems()">
              Удалить
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
                color="primary"
                v-model="form.search"
                filled
                search
                placeholder="Поиск по названию команды"
                dense
                @keyup="searchTeams()"
            >
              <v-icon
                  slot="prepend-inner"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-data-table
                :headers="table.headers"
                :items="table.list"
                v-model="table.selected"
                :single-select="false"
                show-select
                item-key="id"
                class="table"
                :loading="table.loading"
                :server-items-length="table.meta.total"
                ref="table"
                :options.sync="table.options"
                :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
                @update:options="optionsUpdated"
            >

              <template v-slot:item.name="{ item }">
                {{
                  festival.settings.team_category === 'region' ? `${item.teamable.fullname}` : `${item.teamable.name}`
                }}
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="table-actions d-flex">
                  <v-tooltip left content-class="tooltip-gto">
                    <template #activator="{attrs, on}">
                      <v-icon
                          class="mr-2"
                          v-if="canEdit(item)"
                          v-bind="attrs"
                          v-on="on"
                          @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    Редактировать
                  </v-tooltip>
                  <v-tooltip left content-class="tooltip-gto">
                    <template #activator="{attrs, on}">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    Удалить
                  </v-tooltip>
                </div>
              </template>

            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog
            v-model="dialog.show"
            persistent
            max-width="480"
        >
          <v-card>
            <v-card-title class="headline">
              Добавить команду
            </v-card-title>
            <v-card-text class="pb-0">
              <label>Категория команд:</label>
              <p class="mb-0">{{ teamCategoryLabel }}</p>
            </v-card-text>
            <v-form>
              <ValidationObserver
                  ref="dialogObserver"
                  v-slot="{ invalid }"
              >

                <v-card-text v-if="shouldBeSearchable">
                  <ValidationProvider
                      :name="teamCategoryLabel"
                      rules="required"
                      v-slot="{ errors }"
                  >
                    <v-autocomplete
                        v-model="dialog.form.items.selected"
                        :items="dialog.form.items.list"
                        :search-input.sync="dialog.form.searchString"
                        :label="teamCategoryLabel"
                        filled
                        :loading="dialog.form.search.loading"
                        item-value="id"
                        :item-text="(festival && festival.settings.team_category === 'region') ? 'fullname' : 'name'"
                        :no-filter="festival.settings.team_category === 'municipality'"
                        :placeholder="festival.settings.team_category === 'municipality' ? 'Начните вводить текст' : ''"
                        return-object
                        hide-details
                        :error-messages="errors"
                        chips
                        deletable-chips
                        multiple
                        dense
                        small-chips
                        :readonly="dialog.form.loading"
                        @change="dialog.form.searchString = ''"
                        class="pb-3"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="toggleSelectAllItems"
                        >
                          <v-list-item-action>
                            <v-icon :color="dialog.form.items.selected.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Выбрать все
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>

                      <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                            class="mt-1 ml-0 mr-1 mb-1"
                            small
                        >
                          {{
                            festival.settings.team_category === 'region' ? `${data.item.fullname}` : `${data.item.name}`
                          }}
                        </v-chip>
                      </template>

                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title> {{
                              festival.settings.team_category === 'region' ? `${data.item.fullname}` : `${data.item.name}`
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ data.zip }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-card-text>

                <v-card-text v-else>
                  <ValidationProvider
                      :name="teamCategoryLabel"
                      rules="required"
                      v-slot="{ errors }"
                  >
                    <v-combobox
                        v-model="dialog.form.items.selected"
                        :items="dialog.form.items.list"
                        :label="teamCategoryLabel"
                        filled
                        :loading="dialog.form.search.loading"
                        item-value="id"
                        item-text="name"
                        no-filter
                        return-object
                        hide-details
                        placeholder="Введите название команды и нажмите Enter"
                        :error-messages="errors"
                        chips
                        deletable-chips
                        multiple
                        tags
                        :readonly="dialog.form.loading"
                        dense
                    >
                      <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                            class="mt-1 ml-0 mr-1 mb-1"
                            small
                        >
                          {{ `${data.item}` }}
                        </v-chip>
                      </template>
                    </v-combobox>

                  </ValidationProvider>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      :disabled="invalid || dialog.form.loading"
                      :loading="dialog.form.loading"
                      @click.prevent="storeTeams()"
                  >
                    Добавить
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      outlined
                      @click="dialog.show = false"
                      :disabled="dialog.form.loading"
                  >
                    Отменить
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="deleteDialog.show"
            persistent
            max-width="480"
        >
          <v-card>
            <v-card-title class="headline">
              В команде присутствуют участники
            </v-card-title>
            <v-card-text>
              Нельзя удалить команду в которой есть участники
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  outlined
                  @click="deleteDialog.show = false"
              >
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="teamEditDialog.show"
            persistent
            max-width="480"
        >
          <v-card>
            <v-card-title class="headline">
              Редактировать команду
            </v-card-title>

            <v-form>
              <ValidationObserver
                  ref="dialogObserver"
                  v-slot="{ invalid }"
              >
                <v-card-text>
                  <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
                  >
                    <v-text-field
                        filled
                        hide-details
                        dense
                        label="Название команды"
                        :error-messages="errors"
                        v-model="teamEditDialog.form.editable.teamable.name"
                        :disabled="teamEditDialog.form.loading"
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-card-text>


                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      :disabled="invalid || teamEditDialog.form.loading"
                      :loading="teamEditDialog.form.loading"
                      @click="updateItem()"
                  >
                    Сохранить
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      outlined
                      @click="teamEditDialog.show = false"
                      :disabled="teamEditDialog.form.loading"
                  >
                    Отменить
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-form>
          </v-card>
        </v-dialog>

      </div>

      <div v-show="!canCreateTeams">
        <v-alert
            border="bottom"
            colored-border
            type="warning"
            elevation="2"
        >
          Добавление команд невозможно, измените тип фестиваля на "Командный и личный"
        </v-alert>
      </div>

    </div>


    <remove-item ref="remover"
                 :populate-with="table.selected"
                 :type="'festivalTeams'"
                 @remove-item-success="removed()"></remove-item>
  </div>


</template>
<script>
import {mapState, mapActions} from 'vuex';
import {setInteractionMode, ValidationProvider, ValidationObserver} from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');

import FestivalTabsComponent from '@/views/Festival/components/festival-tabs-component';
import RemoveItem from '@/components/modals/remove-item';
import {api} from '@/api';
import debounce from 'debounce';

export default {
  name: 'FestivalTeams',
  components: {FestivalTabsComponent, ValidationProvider, ValidationObserver, RemoveItem},
  data() {
    return {
      festival: null,
      countries: [],
      regions: [],
      form: {
        loading: false,
        search: null
      },
      page: {
        loading: true
      },
      table: {
        selected: [],
        headers: [
          {
            text: '№',
            align: 'start',
            value: 'number',
            width: '7%'
          },
          {text: 'Название', value: 'name', width: '43%'},
          {text: 'Мужчин', value: 'members_male_count', sortable: false, width: '10%'},
          {text: 'Женщин', value: 'members_female_count', sortable: false, width: '10%'},
          {text: 'Всего участников', value: 'members_count', sortable: false, width: '15%'},
          {text: '', value: 'actions', sortable: false, width: '10%'},
        ],
        list: [],
        meta: {
          total: -1
        },
        loading: false
      },
      dialog: {
        show: false,
        form: {
          loading: false,
          search: {
            loading: false,
          },
          items: {
            selected: [],
            list: []
          },
          searchString: null
        }
      },
      deleteDialog: {
        show: false
      },
      teamEditDialog: {
        show: false,
        form: {
          editable: null,
          loading: false,
        }
      }
    }
  },
  watch: {
    'dialog.form.searchString': function (value) {
      if (value) this.search(value, this);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    shouldBeSearchable() {
      return ['country', 'region', 'municipality'].includes(this.festival.settings.team_category);
    },
    teamCategoryLabel() {
      if (this.festival) {
        switch (this.festival.settings.team_category) {
          case 'country' :
            return 'Страна';
          case 'region':
            return 'Регион';
          case 'municipality':
            return 'Муниципальное образование';
          case 'corporate':
            return 'Корпоратив';
          case 'school':
            return 'Школа';
          case 'other':
            return 'Другое';
        }
      }
      return null;
    },
    canCreateTeams() {
      if (this.festival && this.festival.settings) {
        return (this.festival.settings.credit_type === 'team_and_personal');
      }
      return false;
    },
    selectedAll() {
      return this.dialog.form.items.selected.length === this.dialog.form.items.list.length
    },
    selectedSome() {
      return this.dialog.form.items.selected.length > 0 && !this.selectedAll
    },
    icon() {
      if (this.selectedAll) return 'mdi-close-box'
      if (this.selectedSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    ...mapActions('app', ['notify']),
    ...mapActions('festival', ['set_festival']),
    async fetch() {
      this.table.loading = true;
      try {

        let response = await api.festival.teams.all(this.$route.params.id, {
          search: this.form.search,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.festival = response.data.festival;
        this.set_festival(this.festival);

        this.countries = response.data.countries.list;
        this.regions = response.data.regions;

        this.table.list = response.data.list;
        this.table.meta = response.data.meta;
      } catch (e) {
        console.error(e);
      }
      this.table.loading = false;
      this.page.loading = false;
    },
    search: debounce(async (value, self) => {
      self.dialog.form.search.loading = true;

      try {
        let response;

        if (self.festival.settings.team_category === 'municipality') {
          response = await api.kladr.get({query: value, contentType: 'city'})
          self.dialog.form.items.list = [...response.data.list, ...self.dialog.form.items.selected,];// eslint-disable-line
        }

        self.dialog.form.search.loading = false;// eslint-disable-line
      } catch (e) {
        console.error(e);
      }
    }, 500),
    async storeTeams() {
      try {
        this.dialog.form.loading = true;
        await api.festival.teams.store(this.$route.params.id, {teams: this.dialog.form.items.selected})
        this.dialog.show = false;
        this.dialog.form.items.selected = [];
        this.dialog.form.items.list = [];
        this.dialog.form.loading = false;
        await this.fetch();
      } catch (e) {
        console.error(e);
      }
    },
    searchTeams: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated() {
      this.fetch();
    },
    remove(item) {
      let index;
      if (item.id) {
        index = this.dialog.form.items.selected.findIndex(x => x.id === item.id)
      } else {
        index = this.dialog.form.items.selected.findIndex(x => x === item)
      }
      if (index >= 0) this.dialog.form.items.selected.splice(index, 1)
    },
    canEdit(item) {
      console.log(item.category)
      return ['other', 'corporate', 'school'].includes(item.category);
    },
    async deleteItem(item) {
      this.table.selected = []
      this.table.selected.push(item);
      await this.deleteItems();
    },
    editItem(item) {
      this.teamEditDialog.form.editable = JSON.parse(JSON.stringify(item));
      this.teamEditDialog.show = true;
    },
    async updateItem() {
      this.teamEditDialog.form.loading = true
      try {
        await api.festival.teams.update(this.$route.params.id,
            this.teamEditDialog.form.editable.id,
            {name: this.teamEditDialog.form.editable.teamable.name})

        await this.fetch();
        this.teamEditDialog.show = false;
        this.notify('Название изменено')
      } catch (e) {
        console.error(e)
      }
      this.teamEditDialog.form.loading = false
    },
    openCreateDialog() {
      this.dialog.show = true;

      if (this.festival.settings.team_category === 'country') {
        this.dialog.form.items.list = this.countries;
      }

      if (this.festival.settings.team_category === 'region') {
        this.dialog.form.items.list = this.regions;
      }
    },
    toggleSelectAllItems() {
      if (this.selectedAll) {
        this.dialog.form.items.selected = []
      } else {
        this.dialog.form.items.selected = this.dialog.form.items.list
      }

    },
    async deleteItems() {
      let teamHasMembers = false;
      for (let i = 0; i < this.table.selected.length; i++) {
        if (this.table.selected[i].members_count > 0) {
          teamHasMembers = true;
          break;
        }
      }

      if (teamHasMembers) {
        this.deleteDialog.show = true;
      } else {
        this.$refs.remover.dialog = true;
      }
    },
    removed() {
      this.notify('Команды успешно удалены');
      this.table.selected = []
      this.fetch();
    },
  },
}
</script>

<style scoped>

</style>
