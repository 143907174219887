var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('festival-tabs-component',{attrs:{"festival":_vm.festival}}),(_vm.page.loading)?_c('div',{staticClass:"text-center pa-10"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.page.loading),expression:"!page.loading"}]},[_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"d-flex flex-column flex-lg-row",attrs:{"cols":"12","lg":"6"}},[_c('v-btn',{staticClass:"mb-3 mb-lg-0 mr-0 mr-lg-3",attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){return _vm.addJudges()}}},[_vm._v(" Добавить судей ")]),_c('v-btn',{attrs:{"color":"primary","outlined":"","text":"","disabled":_vm.canRemoveJudges},on:{"click":function($event){_vm.$refs.remover.dialog = true}}},[_vm._v(" Удалить ")])],1),_c('v-col',[_c('v-text-field',{attrs:{"color":"primary","filled":"","search":"","placeholder":"Поиск судьи","dense":"","hide-details":""},on:{"keyup":_vm.search},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" mdi-magnify ")])],1)],1)],1),_c('v-data-table',{ref:"table",staticClass:"table",attrs:{"headers":_vm.table.headers,"items":_vm.table.list,"item-key":"id","loading":_vm.table.loading,"server-items-length":_vm.table.meta.total,"single-select":false,"show-select":"","options":_vm.table.options,"footer-props":{
            itemsPerPageOptions : [10,25,50,-1]
          }},on:{"update:options":[function($event){return _vm.$set(_vm.table, "options", $event)},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:"item.profile",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.profile.id)+" ")]}},{key:"item.fullname",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.profile.fullname)+" ")]}},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.email)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_vm._v(" Удалить ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('create-judges-component',{ref:"judgesFormComponent",on:{"submitted":function($event){return _vm.judgesAdded()}}}),_c('remove-item',{ref:"remover",attrs:{"populate-with":_vm.selectedJudges,"type":'judge'},on:{"remove-item-success":_vm.removed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }