import {guard} from '@/guards/guard';
import Svod from '@/views/Svod/Svod';
import SvodIndex from '@/views/Svod/Index';
import SvodLogs from '@/views/Svod/Logs';

export default {
    path: '/svods',
    component: Svod,
    meta: {title: 'Свод'},
    children: [
        {path: '', name: 'svod.index', beforeEnter: guard, component: SvodIndex, meta: {title: 'Свод'}},
        {path: 'logs', name: 'svod.logs.index', beforeEnter: guard, component: SvodLogs, meta: {title: 'Логи загрузки СВОД', goBackTo: 'svod.index'}},
    ]
}
