<template>
    <v-form>
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
      >
        <div class="mb-6">
        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              :skip-if-empty="false"
              name="Имя"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.firstname"
                filled
                label="Имя"
                :error-messages="errors"
                dense
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              :skip-if-empty="false"
              name="Фамилия"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.surname"
                filled
                label="Фамилия"
                :error-messages="errors"
                dense
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              v-slot="{ errors }"
              :skip-if-empty="false"
              name="Отчество"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.middlename"
                filled
                label="Отчество"
                :error-messages="errors"
                dense
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              v-slot="{ errors }"
              :skip-if-empty="false"
              name="УИН"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.uin"
                filled
                label="УИН"
                :error-messages="errors"
                dense
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors }"
              :skip-if-empty="false"
              name="Email"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.email"
                filled
                label="Email"
                :error-messages="errors"
                dense
                disabled
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              v-slot="{ errors }"
              :skip-if-empty="true"
              vid="old_password"
              rules="required_if:new_password|alpha_num"
              name="Старый пароль"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.old_password"
                filled
                label="Старый пароль"
                :error-messages="errors"
                dense
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              rules="required_if:old_password|alpha_dash|min:8|max:16"
              v-slot="{ errors }"
              :skip-if-empty="true"
              vid="new_password"
              name="Новый пароль"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.new_password"
                filled
                label="Новый пароль"
                :error-messages="errors"
                dense
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="pa-3 pb-0 mt-0">
          <v-col cols="12" lg="4" md="6" class="pb-0">
            <ValidationProvider
              rules="required_if:old_password|alpha_dash|min:8|max:16|confirmed:new_password"
              v-slot="{ errors }"
              :skip-if-empty="true"
              name="Повторите пароль"
            >
              <v-text-field
                color="primary"
                class="mr-md-2"
                v-model="form.new_password_repeat"
                filled
                label="Повторите пароль"
                :error-messages="errors"
                dense
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

      </div>

      <div class="sticky-actions">
        <v-divider></v-divider>

        <v-row class="pa-3">
          <v-col cols="12" class="d-flex flex-column flex-md-row">
            <v-btn color="primary" class="mb-3 mb-md-0 mr-0 mr-md-3" :disabled="invalid || form.loading" @click="submit"
                   :loading="form.loading">
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </div>
      </ValidationObserver>
    </v-form>
</template>
<script>

import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

import {mapState, mapMutations, mapActions} from 'vuex';
import {api} from '@/api';

export default {
  name: 'ProfileEdit',
  components: {  ValidationObserver,  ValidationProvider},
  data: function () {
    return {
      form: {
        loading: false,
        firstname: null,
        surname: null,
        middlename: null,
        uin: null,
        email: null,
        old_password: null,
        new_password: null,
        new_password_repeat: null,
      }
    }
  },
  mounted() {
    this.form.firstname = this.user.profile.name;
    this.form.surname = this.user.profile.surname;
    this.form.middlename = this.user.profile.middlename;
    this.form.uin = this.user.profile.id;
    this.form.email = this.user.email;
  },
  computed: {
    ...mapState('auth', ['user']),

  },
  methods: {
    ...mapMutations('auth', [
      'set_user',
    ]),
    ...mapActions('app', [
      'notify',
      'error',
    ]),
    cancel() {

    },
    async submit() {
      try {
        const response = await api.profile.update(this.form)
        this.set_user(response.data.data)

        this.notify('Информация о профиле успешно сохранена')

      } catch (e) {
        if (e.response.data.error === 'Old password is incorrect'){
          this.error('Старый пароль не совпадает')
        }
      }

    }
  }

}
</script>

<style scoped>

</style>
