<template>
  <div class="image-uploader d-flex flex-column" :class="{ disabled: disabled}">

    <input type="file" ref="fileInput" style="display: none" accept="image/png, image/gif, image/jpeg"
           @change="fileSelected()">

    <label class="d-flex"><strong>{{ label }}</strong></label>
    <p v-if="hint" class="text-caption">{{ hint }}</p>
    <div class="image-uploader__container d-flex flex-grow">

      <div v-if="!file" class="image-uploader__no-image d-flex justify-center align-center"
           :style="{ height: height, width: width }"
             :class="loading ? 'loading' : null"
             @click="openInput()">


          <v-icon v-if="!loading">
            mdi-upload
          </v-icon>
          <v-progress-circular
              v-else
              indeterminate
              color="primary"
          ></v-progress-circular>

        </div>
        <div v-else class="image-uploader__image">
          <div class="remove-image">
            <v-icon color="white" @click="remove()">
              mdi-delete-outline
            </v-icon>
          </div>
          <img :src="file.url"/>
        </div>
      </div>


    <image-cropper-component v-if="useCrop" ref="cropper" :src="imageSrc" :settings="cropSettings"
                             @cropped="setCroppedImage"></image-cropper-component>
  </div>
</template>

<script>

import {api} from '@/api';
import {mapActions} from 'vuex';
import ImageCropperComponent from '@/components/common/image-cropper-component';

export default {
  name: 'image-upload-component',
  components: {ImageCropperComponent},
  props: {
    label: {
      type: String,
      default: ''
    },
    useCrop: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: '100%'
    },
    width: {
      type: String,
      default: '100%'
    },
    fileData: {
      type: Object,
      default() {
        return null
      }
    },
    validation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cropSettings: {
      type: Object,
      default() {
        return {aspectRatio: 3.5 / 4.5}
      }
    }
  },
  mounted() {
    this.$refs.fileInput.value = ''
  },
  watch: {
    fileData: function (newVal) {
      this.file = newVal
    }
  },
  data() {
    return {
      loading: false,
      file: this.fileData,
      imageSrc: null,
    }
  },
  methods: {
    ...mapActions('app', ['error']),
    openInput() {
      if (!this.disabled) {
        this.$refs.fileInput.click()
      }
    },
    async fileSelected() {
      let reader = new FileReader();
      let image = this.$refs.fileInput.files[0];

      console.log(image.size);

      reader.readAsDataURL(image);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          try {
            if (this.validation.width && this.validation.width !== img.width) {
              throw 'Ширина изображения должна быть: ' + this.validation.width + 'px';
            }

            if (this.validation.height && this.validation.height !== img.height) {
              throw 'Высота изображения должна быть: ' + this.validation.height + 'px';
            }

            if (image.size > 1024 * 1024 * 5) {
              throw  'Максимальный размер файла: 5мб';
            }

            if (this.useCrop) {
              this.imageSrc = reader.result;
              this.$refs.cropper.show = true;
            } else {
              this.imageSrc = reader.result;
              this.upload();
            }

          } catch (e) {
            this.error(e)
          }


        }
      }


    },
    validate(img) {
      console.log(img)
    },
    async upload() {
      try {
        if (this.$refs.fileInput.files[0]) {
          this.loading = true;
          let formData = new FormData();
          formData.append('file', this.$refs.fileInput.files[0]);
          let response = await api.file.save(formData);
          this.$refs.fileInput.value = ''
          this.file = response.data;
          this.$emit('update:file', this.file)
          this.loading = false;

        }
      } catch (e) {
        console.error(e);
      }

    },
    remove() {
      this.file = null
      this.$emit('update:file', this.file)
    },
    setCroppedImage(blob) {
      this.$refs.cropper.show = false;
      let container = new DataTransfer();
      let file = new File([blob], 'image.png', {type: 'image/png', lastModified: new Date().getTime()});
      container.items.add(file);
      this.$refs.fileInput.files = container.files;
      this.upload();
    }
  }
}
</script>

<style scoped lang="scss">
.image-uploader {
  height: 100%;
  width: 100%;
  position: relative;

  &.disabled {
    opacity: 0.6;
  }

  &__container {
    background: rgba(33, 33, 33, 0.08);
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__no-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      .remove-image {
        display: flex;
      }
    }

    .remove-image {
      position: absolute;
      align-items: center;
      background: #00000038;
      top: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      display: none;
    }
  }
}


</style>