<template>
    <router-view></router-view>
</template>
<script>
export default {
  name: 'Auth'
}
</script>

<style scoped>

</style>
