<script>
import FestivalViewTabsComponent from '@/views/Festival/components/festival-view-tabs-component';
import FestivalRelayRaceHeader from "@/views/Festival/components/relay-race/FestivalRelayRaceHeader.vue";
import FestivalRelayRaceTable from "@/views/Festival/components/relay-race/FestivalRelayRaceTable.vue";
import { api } from "@/api";
import { mapActions } from "vuex";

export default {
  name: 'FestivalTournamentTable',

  components: {
    FestivalViewTabsComponent,
    FestivalRelayRaceHeader,
    FestivalRelayRaceTable,
  },

  data() {
    return {
      festival: null,
      items: [],
      isAutomaticCalculation: false,
      searchQuery: '',
    }
  },

  computed: {
    getItems() {
      if (this.searchQuery.length) {
        return this.items.filter((item) => {
          return item.team.teamable.name
            .toLowerCase()
            .startsWith(this.searchQuery.toLowerCase())
        })
      }

      return this.items
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    ...mapActions('app', ['notify', 'error']),

    async fetch() {
      try {
        const response = await api.festival
          .get(this.$route.params.id)

        this.festival = response.data.festival
        this.isAutomaticCalculation = this.festival?.relay_points_autocalculate

        const { data } = await api.festival.relayRace
          .all(this.$route.params.id, {
            sortBy: 'base_team_place'
          })

        this.items = data.list
      } catch (error) {
        console.error(error)
      }
    },

    async setAutomaticCalculation(newValue) {
      this.isAutomaticCalculation = newValue

      try {
        await api.festival
          .update(this.$route.params.id, {
            ...this.festival,
            relay_points_autocalculate: newValue,
            languages: this.festival?.languages
              .map((language) => language.short_name),
          })

        this.notify('Данные обновлены!')
      } catch (error) {
        this.error(error.response.data.error)
      }
    },
  }
}
</script>

<template>
  <div>
    <FestivalViewTabsComponent has-relay />

    <div class="pa-3">
      <FestivalRelayRaceHeader
        :automatic-calculation="isAutomaticCalculation"
        @automatic-calculation="setAutomaticCalculation"
        @search="searchQuery = $event"
      />
      <FestivalRelayRaceTable
        :items="getItems"
        :automatic-calculation="isAutomaticCalculation"
      />
    </div>
  </div>
</template>
