<template>
  <div>
    <festival-tabs-component :festival="festival"></festival-tabs-component>

    <div v-if="page.loading" class="text-center pa-10">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>

    <v-form v-else>
      <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
      >
        <v-row class="pa-3">
          <v-col cols="12"><h5 class="text-h6">Цветовая гамма</h5></v-col>
        </v-row>
        <v-row class="pa-3 mt-0 pt-0">
          <v-col cols="12" md="4" lg="3">
            <label><strong>Основной цвет</strong></label>
            <v-color-picker v-model="form.main_color"></v-color-picker>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <label><strong>Цвет градиета</strong></label>
            <v-color-picker v-model="form.gradient_color"></v-color-picker>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <label><strong>Дополнительный цвет</strong></label>
            <v-color-picker v-model="form.extra_color"></v-color-picker>
          </v-col>
        </v-row>

        <v-row class="pa-3 pt-0 mt-0">
          <v-col cols="12">
            <v-btn color="primary" @click="setDefaultColorsSchema()" text outlined>Восстановить по умолчанию (ГТО)</v-btn>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row class="pa-3">
          <v-col cols="12"><h5 class="text-h6">Фирменный стиль</h5></v-col>
        </v-row>

        <v-row class="pa-3 mt-0 pt-0">
          <v-col cols="12" lg="9">
            <div style="max-width: 340px">
            <image-upload-component :validation="{width:340, height: 100}" :label="'Логотип мероприятия'"
                                    :file-data="form.logo" :hint="'Максимальный размер 340*100 px'"
                                    :file.sync="form.logo"></image-upload-component>
            </div>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row class="pa-3 mt-0 pt-0">
          <v-col cols="12" lg="9">
            <image-upload-component :validation="{width:1920, height: 757}" :file-data="form.background"
                                    :label="'Фоновое изображение на главной странице'"
                                    :hint="'Максимальный размер 1920*757 px. Изображение должно быть темным, чтобы читался белый текст'"
                                    :file.sync="form.background"></image-upload-component>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <interface-logos-component :logos-data="form.organizers" ref="organizers"></interface-logos-component>

        <v-divider></v-divider>

        <v-row class="pa-3 pb-0">
          <v-col cols="12"><h5 class="text-h6">Дополнительные изображения</h5></v-col>
        </v-row>

        <v-row class="pa-3">
          <v-col cols="12" lg="9">
            <v-row class="mb-3">
              <v-col cols="12" md="6">
                <image-upload-component
                    :validation="{width:1420, height: 800}"
                    :disabled="!isRuLanguageSelected"
                    :file-data="form.sharing_image_ru"
                    :hint="'Размер 1420*800 px'"
                    :label="'Картинка для шаринга'"
                    :file.sync="form.sharing_image_ru"></image-upload-component>
              </v-col>
              <v-col cols="12" md="6">
                <image-upload-component
                    :validation="{width:1420, height: 800}"
                    :disabled="!isEnLanguageSelected"
                    :file-data="form.sharing_image_en"
                    :hint="'Размер 1420*800 px'"
                    :label="'Картинка для шаринга (EN)'"
                    :file.sync="form.sharing_image_en"></image-upload-component>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mb-3 mt-3">
              <v-col cols="12">
                <image-upload-component
                    :validation="{width:1420, height: 800}"
                    :file-data="form.map_image"
                    :hint="'Размер 1420*800 px'"
                    :label="'Карта фестиваля'"
                    :file.sync="form.map_image"></image-upload-component>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row  class="mb-3 mt-3">
              <v-col cols="12">
                <image-upload-component
                    :validation="{width:1920, height: 1800}"
                    :file-data="form.stub_image"
                    :hint="'Размер 1920*1800 px'"
                    :label="'Картинка для заглушки'"
                    :file.sync="form.stub_image"></image-upload-component>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <interace-sponsors-component :partners-data="form.partners" ref="sponsors"></interace-sponsors-component>

        <div class="sticky-actions">
          <v-divider></v-divider>

          <v-row class="pa-3">
            <v-col cols="12">
              <v-btn color="primary" :disabled="invalid || form.loading" @click="submit" :loading="form.loading">
                Сохранить
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </ValidationObserver>
    </v-form>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import {ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');

import FestivalTabsComponent from '@/views/Festival/components/festival-tabs-component';
import {api} from '@/api';
import InterfaceLogosComponent from '@/views/Festival/components/interface/interface-logos-component';
import InteraceSponsorsComponent from '@/views/Festival/components/interface/interace-sponsors-component';
import ImageUploadComponent from '@/components/common/image-upload-component';

export default {
  name: 'FestivalInterface',
  components: {
    ImageUploadComponent,
    InteraceSponsorsComponent,
    InterfaceLogosComponent, FestivalTabsComponent, ValidationObserver},
  data() {
    return {
      festival: null,
      form: {
        loading: false,
        main_color: '#FFFFFF',
        gradient_color: '#FFFFFF',
        extra_color: '#FFFFFF',
        logo: null,
        background: null,
        organizers: [],
        sharing_image_ru: null,
        sharing_image_en: null,
        map_image: null,
        stub_image: null,
        partners: [{
          title_ru: null,
          title_en: null,
          logos: []
        }],
      },
      page: {
        loading: true
      }
    }
  },
  mounted() {
    this.fetch()
  },
  computed: {
    ...mapState('auth', ['user']),
    isRuLanguageSelected(){
      return this.festival.languages.includes('ru');
    },
    isEnLanguageSelected(){
      return this.festival.languages.includes('en');
    },
  },
  methods: {
    ...mapActions('app', ['notify']),
    ...mapActions('festival', ['set_festival']),
    async fetch() {
      try {
        let response = await api.festival.interface.get(this.$route.params.id);
        response.data.languages = response.data.languages.map(x => x.short_name)
        this.festival = response.data;
        this.set_festival(this.festival)
        this.form = this.festival.interface;
        this.page.loading = false
      } catch (e) {
        console.error(e);
      }
    },
    setDefaultColorsSchema(){
      this.form.extra_color = '#bb9657';
      this.form.main_color = '#d31f34';
      this.form.gradient_color = '#88101d';
    },
    async submit() {
      this.$set(this.form, 'loading', true);
      try {
        this.form.organizers = this.$refs.organizers.logos;
        this.form.partners = this.$refs.sponsors.partners
        await api.festival.interface.update(this.$route.params.id, this.form);
        this.notify('Сохранено')
      } catch (e) {
        console.error(e);
      }
      this.form.loading = false;
    },

  },
}
</script>

<style scoped>

</style>
