<template>
    <component :is="layout" :style="{ background: $vuetify.theme.themes.light.background }"/>
</template>

<script>
const default_layout = 'default';
import {mapActions} from 'vuex';

export default {
    name: 'App',
    created () {
        window.addEventListener('resize', this.resize);
        this.resize();
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || default_layout) + '-layout';
        }
    },
    data() {
        return {};
    },
   methods: {
      ...mapActions('app', ['setPlatform']),
      resize(){
        if (window.innerWidth > 600){
          this.setPlatform('desktop');
        } else {
          this.setPlatform('mobile');
        }
      }
   }
};
</script>
