<template>
  <div class="mb-3">
    <v-row class="pa-3">
      <v-col cols="12">
        <h5 class="text-h6">Логотипы организаторов</h5>
        <p class="text-caption mb-3">Размер: 135*135 px, максимум 6 штук</p>
        <v-btn color="primary" @click="add()" :disabled="logos.length >= 6">Добавить логотип</v-btn>
      </v-col>
    </v-row>

    <div>
      <draggable v-model='logos' draggable=".item" handle=".handle" group="logos">
        <v-row class="pa-3 pt-0 item" v-for="(logo, index) in logos" :key="index">
        <v-col cols="12" lg="9" class="d-flex pb-0 ">
          <div class="mr-3 d-flex align-center">
            <v-btn text class="handle">
              <v-icon>mdi-drag-horizontal-variant</v-icon>
            </v-btn>
          </div>
          <div style="min-width: 92px;min-height: 92px" class="mr-3">
            <image-upload-component :validation="{width:135, height: 135}" :height="'100%'" :file-data="logo.logo"
                                    :file.sync="logo.logo"></image-upload-component>
          </div>
          <div class="d-flex flex-grow-1 flex-column">
            <v-text-field v-model="logo.link" hide-details outlined label="Ссылка" dense></v-text-field>
            <div class="d-flex ">
              <v-btn color="primary" text outlined @click="remove(index)">Удалить логотип</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      </draggable>
    </div>


  </div>
</template>

<script>
import ImageUploadComponent from '@/components/common/image-upload-component';
import draggable from 'vuedraggable'

export default {
  name: 'interface-logos-component',
  components: {ImageUploadComponent, draggable},
  props: {
    logosData: {
      type: Array,
      default: function (){
        return []
      }
    }
  },
  watch: {
    logos(val){
      this.$emit('update:logos', val)
    }
  },
  data(){
    return {
      logos: this.logosData
    }
  },
  methods: {
    add(){
      this.logos.push({
        link: null,
        logo: null
      })
    },
    remove(index){
      this.logos.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>