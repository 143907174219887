<template>
  <div>
    <router-view></router-view>
    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" class="mb-6 mb-md-0">

        <svod-upload-form-component></svod-upload-form-component>

        <div class="mt-3 mb-3">

        </div>

      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template v-slot:item.created_at="{ item }">
            {{ (new Date(item.created_at)).toLocaleDateString() }} {{
              (new Date(item.created_at)).toLocaleTimeString()
            }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="table-actions">
              <v-tooltip left content-class="tooltip-gto">
                <template #activator="{on, attrs}">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.file"
                      link
                      icon
                      :href="item.file.url"
                      target="_blank"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                Скачать xlsx
              </v-tooltip>

              <v-tooltip left content-class="tooltip-gto">
                <template #activator="{on, attrs}">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      v-if="canDelete(item)"
                      @click="deleteItem(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Удалить
              </v-tooltip>

            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <remove-item ref="remover" :populate-with="selected" :type="'svod'" @remove-item-success="removed"></remove-item>
  </div>
</template>
<script>


import {api} from '@/api'
import {mapState, mapActions} from 'vuex';
import SvodUploadFormComponent from '@/views/Svod/components/svod-upload-form-component';
import permissions from '@/constants/permisions';
import RemoveItem from '@/components/modals/remove-item';

export default {
  name: 'SvodIndex',
  components: {SvodUploadFormComponent, RemoveItem},
  data() {
    return {
      table: {
        headers:  [
          {
            text: 'ID',
            align: 'start',
            value: 'id',
          },
          { text: 'Название', value: 'name' },
          { text: 'Дата загрузки', value: 'created_at', sortable: false },
          { text: 'Начало действия', value: 'started_at', sortable: false },
          { text: 'Автор', value: 'user.Profile.fullname', sortable: false },
          { text: '', value: 'actions', sortable: false}
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      form: {
        loading: false
      },
      selected: null,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify']),
    async fetch(){
      try {
        this.form.loading = true;

        let response = await api.svod.all({
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.table.list = response.data.list
        this.table.meta = response.data.meta
        this.form.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    optionsUpdated(){
      this.fetch();
    },
    canDelete(item){
      return permissions.actions.deleteSvod(this, item);
    },
    deleteItem(item){
      this.selected = item;
      this.$refs.remover.dialog = true;
    },
    removed(){
      this.fetch();
      this.notify('Свод удален')
    }
  },
}
</script>

<style scoped>

</style>
