var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-3 pb-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-6 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:('users.create'),expression:"'users.create'"}],attrs:{"color":"primary"},on:{"click":_vm.showCreateUserModal}},[_vm._v(" Добавить пользователя ")])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","placeholder":"Поиск УИН, email или фамилии","dense":""},on:{"keyup":_vm.searchUsers},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" mdi-magnify ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.rolesList,"item-text":"title","item-value":"name","persistent-hint":"","single-lins":"","placeholder":"Все роли","filled":"","dense":""},on:{"change":_vm.changeRole},model:{value:(_vm.form.role.selected),callback:function ($$v) {_vm.$set(_vm.form.role, "selected", $$v)},expression:"form.role.selected"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{ref:"table",staticClass:"table",attrs:{"headers":_vm.table.headers,"items":_vm.table.list,"item-key":"id","loading":_vm.form.loading,"server-items-length":_vm.table.meta.total,"options":_vm.table.options,"footer-props":{
            itemsPerPageOptions : [10,25,50,-1]
          }},on:{"update:options":[function($event){return _vm.$set(_vm.table, "options", $event)},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.email || "-")+" ")]}},{key:"item.region",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.regionTitle(item))+" ")]}},{key:"item.municipality",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.municipalityTitle(item))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"table-actions d-flex"},[_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [(_vm.canEdit(item))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.canDelete(item))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_vm._v(" Удалить ")])],1)]}}])})],1)],1),_c('create-edit-user',{ref:"createEditUserModal",attrs:{"populate-with":_vm.selectedUser,"regions-data":_vm.regions},on:{"submit-success":_vm.userSubmitted}}),_c('remove-item',{ref:"removeUser",attrs:{"populate-with":_vm.selectedUser,"type":'user'},on:{"remove-item-success":_vm.userRemoved}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }