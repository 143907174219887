var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"mb-6"},[_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","skip-if-empty":false,"name":"Имя"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Имя","error-messages":errors,"dense":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","skip-if-empty":false,"name":"Фамилия"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Фамилия","error-messages":errors,"dense":""},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false,"name":"Отчество"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Отчество","error-messages":errors,"dense":""},model:{value:(_vm.form.middlename),callback:function ($$v) {_vm.$set(_vm.form, "middlename", $$v)},expression:"form.middlename"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false,"name":"УИН"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"УИН","error-messages":errors,"dense":""},model:{value:(_vm.form.uin),callback:function ($$v) {_vm.$set(_vm.form, "uin", $$v)},expression:"form.uin"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|email","skip-if-empty":false,"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Email","error-messages":errors,"dense":"","disabled":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":true,"vid":"old_password","rules":"required_if:new_password|alpha_num","name":"Старый пароль"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Старый пароль","error-messages":errors,"dense":""},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required_if:old_password|alpha_dash|min:8|max:16","skip-if-empty":true,"vid":"new_password","name":"Новый пароль"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Новый пароль","error-messages":errors,"dense":""},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required_if:old_password|alpha_dash|min:8|max:16|confirmed:new_password","skip-if-empty":true,"name":"Повторите пароль"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Повторите пароль","error-messages":errors,"dense":""},model:{value:(_vm.form.new_password_repeat),callback:function ($$v) {_vm.$set(_vm.form, "new_password_repeat", $$v)},expression:"form.new_password_repeat"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"sticky-actions"},[_c('v-divider'),_c('v-row',{staticClass:"pa-3"},[_c('v-col',{staticClass:"d-flex flex-column flex-md-row",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mb-3 mb-md-0 mr-0 mr-md-3",attrs:{"color":"primary","disabled":invalid || _vm.form.loading,"loading":_vm.form.loading},on:{"click":_vm.submit}},[_vm._v(" Сохранить ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }