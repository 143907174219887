var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-3 pb-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-6 mb-md-0",attrs:{"cols":"12","md":"4"}},[(_vm.$gates.hasPermission('exercises.create'))?_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:('users.create'),expression:"'users.create'"}],attrs:{"color":"primary","disabled":_vm.form.loading},on:{"click":_vm.showCreateExerciseModal}},[_vm._v(" Добавить испытание ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","placeholder":"Поиск по названию испытания","dense":""},on:{"keyup":_vm.search},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" mdi-magnify ")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{ref:"table",staticClass:"table",attrs:{"headers":_vm.table.headers,"items":_vm.table.list,"item-key":"id","loading":_vm.form.loading,"server-items-length":_vm.table.meta.total,"options":_vm.table.options,"footer-props":{
            itemsPerPageOptions : [10,25,50,-1]
          }},on:{"update:options":[function($event){return _vm.$set(_vm.table, "options", $event)},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:"item.visibility",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.$gates.hasPermission('exercises.edit.visibility')},on:{"click":function($event){return _vm.toggleVisibility(item)}}},[_c('v-tooltip',{attrs:{"right":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.visibility)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye-off ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.visibility ? "Скрыть" : "Показать")+" ")])],1)]}},{key:"item.title_ru",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",staticStyle:{"max-width":"130px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.title_ru ? item.title_ru : _vm.title_en)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title_ru ? item.title_ru : _vm.title_en))])])]}},{key:"item.description_ru",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",staticStyle:{"max-width":"130px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.description_ru)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.description_ru))])])]}},{key:"item.video_link",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.video_link)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":item.video_link,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1):_vm._e()]}}],null,true)},[_vm._v(" Открыть страницу с видео ")])]}},(_vm.$gates.hasPermission('exercises.edit'))?{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"table-actions"},[_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [(_vm.canEdit(item))?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
          var on = ref.on;
return [(_vm.canDelete(item))?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_vm._v(" Удалить ")])],1)]}}:null],null,true)})],1)],1),_c('create-edit-exercise',{ref:"createEditExerciseModal",attrs:{"populate-with":_vm.selected,"parameters-list":_vm.exercise.parameters,"units-list":_vm.exercise.units},on:{"submit-success":_vm.submitted}}),_c('remove-item',{ref:"remover",attrs:{"populate-with":_vm.selected,"type":'exercise'},on:{"remove-item-success":function($event){return _vm.removed()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }