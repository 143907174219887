<template>
    <div>
      <v-snackbar
          v-model="notification.show"
          :timeout="notification.timeout"
          :left="isDesktop"
          :style="style"
      >
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
       {{ notification.text }}
      </v-snackbar>

      <v-snackbar
          v-model="error.show"
          :timeout="error.timeout"
          :left="isDesktop"
          :style="style"
      >
        <v-icon class="mr-2">mdi-close</v-icon>
        {{ errorText }}
      </v-snackbar>

    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
name: 'notifications-component',
  computed: {
    ...mapState('app', ['notification', 'miniVariant', 'error']),
    ...mapGetters('app', ['isMobile', 'isDesktop']),
    style(){
      let margin = 0;
      if (this.isDesktop && this.miniVariant){
        margin = 85;
      } else if (this.isDesktop && !this.miniVariant){
        margin = 285;
      }

      return { marginLeft: `${margin}px` }
    },
    errorText(){
      if (this.error && this.error.object && this.error.object.response && this.error.object.response.status === 422) {
        let errors = Object.entries(this.error.object.response.data.error).map(([, value]) => {
          return value.join(' ');
        }).join(' ');

        return errors;
      } else if (this.error && typeof this.error.object === 'string') {
        return this.error.object;
      }
      return 'Произошла ошибка выполнения сценария'
    }
  },
}
</script>

<style scoped>

</style>