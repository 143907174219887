import Vue from 'vue';
import VueRouter from 'vue-router';

import festivals from '@/router/routes/festivals';
import svod from '@/router/routes/svod';
import patterns from '@/router/routes/templates';
import exercises from '@/router/routes/exercises';
import users from '@/router/routes/users';
import developer from '@/router/routes/developer';
import userLogs from '@/router/routes/userLogs';
import Analytics from '@/views/Analytics/Analytics';
import profile from '@/router/routes/profile';
import auth from '@/router/routes/auth';
import mobile from './routes/mobile';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: {
      name: 'festivals.index'
    }
  },
  festivals,
  svod,
  patterns,
  exercises,
  users,
  developer,
  userLogs,
  profile,
  auth,
  mobile,
  {
    path: '/analytics',
    name: 'analytics.index',
    component: Analytics,
    beforeEnter() {
      location.href = 'https://datalens.yandex.ru/'
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
