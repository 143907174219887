import {guard} from '@/guards/guard';
import LogsIndex from '@/views/Logs/LogsIndex';
import Logs from '@/views/Logs/Logs';

export default {
    path: '/logs',
    component: Logs,
    meta: {title: 'Действия пользователей'},
    children: [
        {
            path: '',
            name: 'logs.index',
            beforeEnter: guard,
            component: LogsIndex,
            meta: {title: 'Действия пользователей'}
        },
    ]
}
