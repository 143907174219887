import {httpClient} from '@/modules/httpClient.js';
const multipart = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}
export const all = async (data = {}) => httpClient.get('/svods', { params: data });
export const store = async (data = {}) => httpClient.post('/svods', data, multipart);
export const update = async (id, data = {}) => httpClient.put(`/svods/${id}`, data);
export const del = async (id, data = {}) => httpClient.delete(`/svods/${id}`, data);
export const active = async (data = {}) => httpClient.get('/svods/active/get', data);
export const logs = async (data = {}) => httpClient.get('/svods/active/logs', { params: data });
export const cancel = async (data = {}) => httpClient.patch('/svods/active/cancel', data);

export default {
    all,
    store,
    update,
    delete: del,
    active,
    logs,
    cancel
}
