var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-3 pb-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-3 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:('templates.create'),expression:"'templates.create'"}],attrs:{"color":"primary","link":"","to":"/templates/create"}},[_vm._v(" Создать шаблон ")])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex flex-md-row-reverse mr-0 mr-md-5"},[_c('v-checkbox',{staticClass:"mt-0 mb-3 mb-md-0 mt-md-1 d-flex",attrs:{"label":'Только мои шаблоны',"hide-details":""},on:{"change":function($event){return _vm.fetch()}},model:{value:(_vm.form.onlyMine),callback:function ($$v) {_vm.$set(_vm.form, "onlyMine", $$v)},expression:"form.onlyMine"}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","placeholder":"Поиск по названию шаблона","dense":""},on:{"keyup":_vm.search},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" mdi-magnify ")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{ref:"table",staticClass:"table",attrs:{"headers":_vm.table.headers,"items":_vm.table.list,"item-key":"id","loading":_vm.form.loading,"server-items-length":_vm.table.meta.total,"options":_vm.table.options,"footer-props":{
            itemsPerPageOptions : [10,25,50,-1]
          }},on:{"update:options":[function($event){return _vm.$set(_vm.table, "options", $event)},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:"item.region",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.region ? item.region.fullname : '-')+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"table-actions d-flex"},[_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.canEdit(item))?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.canShow(item))?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.showItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")]):_vm._e()]}}],null,true)},[_vm._v(" Просмотреть ")]),_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.canDelete(item))?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_vm._v(" Удалить ")])],1)]}}])})],1)],1),_c('remove-item',{ref:"remover",attrs:{"populate-with":_vm.selected,"type":'template'},on:{"remove-item-success":_vm.removed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }