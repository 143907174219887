import './styles/style.scss';

import Vue from 'vue';
import App from './App.vue';
import VueGates from 'vue-gates';

import MainLayout from '@/layouts/MainLayout.vue';
import LoginLayout from '@/layouts/LoginLayout.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import * as VeeValidate from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru.json';
import * as Rules from 'vee-validate/dist/rules';

import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.use(VueGates, {
    persistent: true
});

Vue.filter('formatDate', function(value) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    if (value) {
        let d = new Date(value)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('.')
    }
})

VeeValidate.localize('ru', ru);
for (let rule in Rules) {
  VeeValidate.extend(rule, Rules[rule])
}

Vue.use(VeeValidate, { inject: false });
Vue.config.productionTip = false;

import Axios from 'axios';

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

Vue.component('default-layout', MainLayout)
Vue.component('no-sidebar-layout', LoginLayout)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');

