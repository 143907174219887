import Auth from '@/views/Auth/Auth.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import Login from '@/views/Auth/Login.vue';
import RestorePassword from '@/views/Auth/RestorePassword.vue';

export default {
    path: '/auth',
    component: Auth,
    children: [
        {
            path: 'reset-password',
            name: 'auth.reset-password',
            component: ResetPassword,
            meta: {
              layout: 'no-sidebar'
            },
        },
        {
            path: 'restore-password',
            name: 'auth.restore-password',
            component: RestorePassword,
            meta: {
              layout: 'no-sidebar'
            },
        },
        {
          path: 'login',
          name: 'auth.login',
          meta: {
            layout: 'no-sidebar'
          },
          component: Login
        },
    ]
}
