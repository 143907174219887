import {guard} from '@/guards/guard';
import Exercise from '@/views/Exercise/Exercise';
import ExerciseIndex from '@/views/Exercise/ExerciseIndex';

export default {
    path: '/exercises',
    beforeEnter: guard,
    component: Exercise,
    children: [
        {path: '', name: 'exercises.index', beforeEnter: guard, component: ExerciseIndex, meta: {title: 'Справочник испытаний'}},
    ]
}