import {guard} from '@/guards/guard';
import Template from '@/views/Template/Template';
import TemplateIndex from '@/views/Template/TemplateIndex';
import TemplateCreate from '@/views/Template/TemplateCreate';
import TemplateEdit from '@/views/Template/TemplateEdit';

export default {
    path: '/templates',
    component: Template,
    children: [
        {path: '', name: 'templates.index', beforeEnter: guard, component: TemplateIndex, meta: {title: 'Шаблоны'}},
        {path: 'create', name: 'templates.create', beforeEnter: guard, component: TemplateCreate, meta: {title: 'Добавить шаблон', goBackTo: 'templates.index'}},
        {path: ':id/show', name: 'templates.show', beforeEnter: guard, component: TemplateEdit, meta: {title: 'Просмотр шаблона', goBackTo: 'templates.index'}},
        {path: ':id/edit', name: 'templates.edit', beforeEnter: guard, component: TemplateEdit, meta: {title: 'Редактировать шаблон', goBackTo: 'templates.index'}},
    ]
}
