<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="640"
    >
      <v-card>
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text>
          Вы уверены, что хотите вернуться?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              outlined
              text
              @click="dontSaveAndGoTo()"
          >
            Да, не сохранять
          </v-btn>
          <v-btn
              color="primary"
              @click="dialog = false"
          >
            Нет, остаться
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'leave-form',
  props: {
    title: {
      type: String,
      default: 'Изменения в шаблоне не сохранены!'
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    dontSaveAndGoTo() {
      this.$emit('goto');
    }
  }
}
</script>

<style scoped>

</style>