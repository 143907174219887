import {guard} from '@/guards/guard';
import User from '@/views/User/User';
import UserIndex from '@/views/User/UserIndex';

export default {
    path: '/users',
    beforeEnter: guard,
    component: User,
    children: [
        {path: '', name: 'users.index', beforeEnter: guard, component: UserIndex, meta: {title: 'Пользователи'}},
    ]
}