import {httpClient} from '@/modules/httpClient.js';

export const save = (data = {}) => httpClient.post('/files', data, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
export default {
    save,
}
