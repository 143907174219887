<template>
  <div>
    <festival-tabs-component :festival="festival"></festival-tabs-component>

    <div v-show="page.loading" class="text-center pa-10">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>

    <div v-show="!page.loading">
      <v-row class="pa-3 pb-0">
        <v-col cols="12"  class="d-flex flex-column flex-lg-row">
          <v-btn color="primary" outlined text
                 class="mb-3 mb-lg-0 mr-0 mr-lg-3"
                 @click="importDialog.show = true">Импорт xlsx
          </v-btn>

          <v-btn color="primary"
                 class="mb-3 mb-lg-0 mr-0 mr-lg-3" :loading="template.loading" :disabled="template.loading" outlined text
                 @click="downloadTemplate()">Экспорт xlsx
          </v-btn>

          <v-btn color="primary"
                 text
                 outlined
                 class="mb-3 mb-lg-0 mr-0 mr-lg-3"
                 @click="createMember()">
            Добавить участника
          </v-btn>
          <v-btn color="primary"
                 :disabled="table.selected.length === 0"
                 class="mb-3 mb-lg-0 mr-0 mr-lg-3"
                 text
                 outlined
                 @click="deleteItems">
            Удалить
          </v-btn>

          <v-spacer></v-spacer>


            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    outlined
                    text
                    v-bind="attrs"
                    v-on="on"
                    :loading="badges.loading"
                >
                  Генерация бейджей
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn text class="fw" @click="generateBadges('A6')">Бейджи А6</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn text class="fw" @click="generateBadges('6x9')">Бейджи 6*9</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn text class="fw" @click="generateBadgesBoth()">Оба типа</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>

        </v-col>
      </v-row>
      <v-row class="pa-3 pt-0">
        <v-col cols="12" md="5">
          <v-text-field
              color="primary"
              v-model="form.search"
              filled
              search
              placeholder="Поиск по ФИО, УИН или email"
              dense
              hide-details
              @keyup="searchMembers()"
          >
            <v-icon
                slot="prepend-inner"
            >
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
          :headers="table.headers"
          :items="table.list"
          v-model="table.selected"
          :single-select="false"
          show-select
          item-key="id"
          class="table"
          :loading="table.loading"
          :server-items-length="table.meta.total"
          ref="table"
          :options.sync="table.options"
          :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
          @update:options="optionsUpdated"
      >
        <template v-slot:item.photo="{ item }">
          <v-tooltip right content-class="tooltip-gto">
            <template #activator="{on, attrs}">
              <div v-bind="attrs" v-on="on">
                <v-avatar
                    v-if="item.photo && item.photo.url"
                    size="40"
                    color="primary"
                >
                  <a @click="fastUploadPhoto(item)">
                  <img

                      :alt="`${item.name} ${item.surname}`"
                      :src="(item.photo.thumbs[0]) ? item.photo.thumbs[0].url : null"
                  >
                  </a>
                </v-avatar>
                <v-avatar  v-else
                            size="40"
                            color="grey"
                            @click="fastUploadPhoto(item)"
                >
                  <v-btn text :loading="item.loading"><v-icon>mdi-upload</v-icon></v-btn>
                </v-avatar>
              </div>
            </template>
            Загрузить фото
          </v-tooltip>
        </template>
        <template v-slot:item.team="{ item }">{{ item.team ? item.team.teamable.name : '-'}}</template>
        <template v-slot:item.country="{ item }">{{ item.country ? item.country.name : '-'}}</template>
        <template v-slot:item.region="{ item }">{{ item.region ? item.region.name : '-'}}</template>
        <template v-slot:item.birthday="{ item }">{{ item.birthday|formatDate}}</template>
        <template v-slot:item.email="{ item }">{{ item.email ? item.email : '-'}}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left content-class="tooltip-gto">
            <template #activator="{on, attrs}">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            Редактировать
          </v-tooltip>
          <v-tooltip left content-class="tooltip-gto">
            <template #activator="{on, attrs}">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            Удалить
          </v-tooltip>
        </template>
      </v-data-table>

    </div>

    <create-edit-member-component :festival="festival"
                                  ref="createEditMemberModal"
                                  :populate-with="selectedMember"
                                  :countries-list="countries"
                                  :teams-list="teams"
                                  :regions-list="regions"
                                  @saved="memberSaved()">
    </create-edit-member-component>

    <remove-item ref="remover"
                 :populate-with="selectedMembers"
                 :type="'festivalMembers'"
                 @remove-item-success="removed()"></remove-item>
    <v-dialog
        v-model="importDialog.show"
        persistent
        max-width="480"
    >
      <v-form>
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card>
            <v-card-title class="text-h5">
              Импортировать список участников
            </v-card-title>
            <v-card-text>
              <ValidationProvider
                  name="Название"
                  rules="required"
                  v-slot="{ errors }"
                  mode="eager"
              >
                <v-file-input
                    v-model="form.file"
                    label="Выберите файл в формате .xlsx"
                    class="ma-0 pa-0"
                    hide-details
                    fille
                    dense
                    filled
                    ref="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :error-messages="errors"
                    :disabled="importDialog.loading"
                ></v-file-input>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  outlined
                  text
                  @click="importDialog.show = false"
                  v-show="!importDialog.loading"
              >
                Отменить
              </v-btn>
              <v-btn
                  color="primary"
                  :disabled="invalid || importDialog.loading"
                  :loading="importDialog.loading"
                  @click="importTemplate()"
              >
                Импортировать
              </v-btn>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-form>
    </v-dialog>


    <input type="file" ref="fileInput" style="display: none" accept="image/png, image/gif, image/jpeg"
           @change="fileSelected()">
    <image-cropper-component ref="cropper" :src="fastUpload.fileSrc" :settings="{ aspectRatio: 3.5/4.5 }"
                             @cropped="setCroppedImage"></image-cropper-component>

    <v-dialog
        v-model="badgesDialog.show"
        persistent
        max-width="480"
    >
      <v-card>
        <v-card-title class="text-h5">
          Идет генерация бейджей...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="importDialog.errorsModal.show"
        max-width="640"
    >
      <v-card>
        <v-card-title class="text-h5">
          Ошибки при импорте файла...
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <ul>
            <li v-for="(item, i) in importDialog.errorsModal.list" :key="`error_${i}`">{{ item }}</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');

import FestivalTabsComponent from '@/views/Festival/components/festival-tabs-component';
import {api} from '@/api';
import CreateEditMemberComponent from '@/views/Festival/components/members/create-edit-member-component';
import RemoveItem from '@/components/modals/remove-item';
import ImageCropperComponent from '@/components/common/image-cropper-component';
import debounce from 'debounce';

export default {
  name: 'FestivalMembers',
  components: {
    ImageCropperComponent,
    RemoveItem,
    CreateEditMemberComponent,
    FestivalTabsComponent,
    ValidationObserver,
    ValidationProvider},
  data() {
    return {
      festival: null,
      countries: [],
      teams: [],
      regions: [],
      form: {
        loading: false,
      },
      page: {
        loading: true
      },
      table: {
        singleSelect: false,
        selected: [],
        loading: false,
        headers: [
          {text: 'Фото', value: 'photo', sortable: false},
          {text: 'УИН', value: 'uin', sortable: true},
          {text: 'Фамилия', value: 'surname', sortable: true},
          {text: 'Имя', value: 'name', sortable: true},
          {text: 'Отчество', value: 'patronymic', sortable: true},
          {text: 'Команда', value: 'team', sortable: false},
          {text: 'Дата рождения', value: 'birthday', sortable: false},
          {text: 'Email', value: 'email', sortable: true},
          {text: 'Страна', value: 'country', sortable: false},
          {text: 'Регион', value: 'region', sortable: false},
          {text: 'Пол', value: 'gender.name_ru', sortable: false},
          {text: '', value: 'actions', sortable: false},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      template: {
        loading: false
      },
      importDialog: {
        show: false,
        batch_id: null,
        interval: null,
        loading: false,
        errorsModal: {
          show: false,
          list: []
        }
      },
      badgesDialog: {
        show: false,
        loading: false,
      },
      fastUpload: {
        item: null,
        fileSrc: null,
      },
      badges: {
        loading: false,
        batch_id: null,
        interval: null
      },
      selectedMember: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    selectedMembers() {
      return {
        festival: this.festival,
        members: this.table.selected
      }
    },
  },
  created() {
    if (this.badges.interval) {
      clearInterval(this.badges.interval);
    }
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),
    ...mapActions('festival', ['set_festival']),
    async fetch() {
      this.table.loading = true;
      try {
        let response = await api.festival.members.all(this.$route.params.id, {
          search: this.form.search,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });
        this.festival = response.data.festival;
        this.set_festival(this.festival);
        this.table.list = response.data.list;
        this.table.meta = response.data.meta;
        this.countries = response.data.countries.list;
        this.teams = response.data.teams.list;
        this.regions = response.data.regions;
        this.page.loading = false
      } catch (e) {
        console.error(e);
      }
      this.table.loading = false;
    },
    optionsUpdated() {
      this.fetch();
    },
    openModal(){
      this.$refs.createEditMemberModal.dialog = true;
    },
    memberSaved(){
      this.fetch();
    },
    createMember(){
      this.selectedMember = null;
      this.openModal();
    },
    editItem(item){
      this.selectedMember = JSON.parse(JSON.stringify(item));
      this.openModal();
    },
    async downloadTemplate() {
      this.template.loading = true;
      try {
        let response = await api.festival.members.export(this.$route.params.id)
        this.download(response.data.file_url, 'festival_members.xlsx')
      } catch (e) {
        this.error(e);
      }
      this.template.loading = false
    },
    async badgesStatus() {
      try {
        let response = await api.festival.members.badgesStatus(this.$route.params.id, {
          batch_id: this.badges.batch_id
        });
        if (response.data.status === 'completed') {
          if (response.data.files) {
            this.download(response.data.files['6x9'])
            this.download(response.data.files['A6'])
          } else if (response.data.file) {
            this.download(response.data.file)
          }

          clearInterval(this.badges.interval);
          this.badgesDialog.show = false;

        }

      } catch (e) {
        this.error(e);
        clearInterval(this.badges.interval);
        this.badgesDialog.show = false;
      }
    },
    async generateBadges(type) {
      this.badgesDialog.show = true;
      try {
        let response = await api.festival.members.badges(this.$route.params.id, {
          members: this.table.selected.map(x => {
            return x.id
          }),
          type: type
        });
        this.badges.batch_id = response.data;
        this.badges.interval = setInterval(this.badgesStatus, 3000);

      } catch (e){
        this.error(e);
      }
    },
    async generateBadgesBoth(){
      await this.generateBadges('all')
    },
    async importTemplate(){
      this.importDialog.loading = true;
      try {
        let formData = new FormData();
        formData.append('file', this.$refs.file.value);
        let response = await api.festival.members.import(this.$route.params.id, this.$route.params.exercise, formData)
        this.importDialog.batch_id = response.data.batch_id;
        this.importDialog.interval = setInterval(this.importTemplateChecker, 5000);
      } catch (e) {
        this.error(e);
        this.importDialog.loading = false;
      }
    },
    async importTemplateChecker() {
      try {
        let response = await api.festival.members.importStatus(this.$route.params.id, {
          batch_id: this.importDialog.batch_id
        });
        if (response.data.status === 'completed') {

          clearInterval(this.importDialog.interval);
          this.importDialog.show = false;
          this.importDialog.loading = false;
          await this.fetch();
        }

        if (response.data.status === 'error') {
          this.importDialog.show = false;
          this.importDialog.loading = false;
          clearInterval(this.importDialog.interval);
          this.importDialog.errorsModal.show = true;
          this.importDialog.errorsModal.list = response.data.messages;
        }

      } catch (e) {
        this.error(e);
        clearInterval(this.importDialog.interval);
        this.importDialog.show = false;
        this.importDialog.loading = false;
      }

      /*
      if (response.data.errors.length === 0){
        this.notify('Таблица импортирована')
        this.importDialog.show = false;
        await this.fetch();
      } else {
        this.notify(`Строка: ${response.data.errors[0].row}; ${response.data.errors[0].msg}`);
      }

       */
    },
    download(dataurl, filename) {
      let a = document.createElement('a');
      a.href = dataurl;
      a.setAttribute('download', filename);
      if (dataurl.includes('.pdf')) {
        a.setAttribute('target', '_blank');
      }
      a.click();
    },
    removed() {
      this.notify('Участники удалены');
      this.table.selected = []
      this.fetch();
    },
    deleteItem(item){
      this.table.selected.push(item)
      this.$refs.remover.dialog = true;
    },
    deleteItems(){
      if (this.table.selected.length > 0){
          this.$refs.remover.dialog = true;
      }
    },
    fastUploadPhoto(item){
      this.$refs.fileInput.click()
      this.fastUpload.item = item;
    },
    fileSelected(){
      let reader = new FileReader();
      let image = this.$refs.fileInput.files[0];

      if (image.size > 1024 * 1024 * 5) {
        this.error('Размер изображения не должен превышать 5МБ')
        return false;
      }

      reader.readAsDataURL(image);
      reader.onloadend = () => {
        this.fastUpload.fileSrc = reader.result;
        this.$refs.cropper.show = true;
      }
    },
    async setCroppedImage(blob){
      this.$refs.cropper.show = false;
      let container = new DataTransfer();
      let file = new File([blob], 'image.png',{type:'image/png', lastModified:new Date().getTime()});
      container.items.add(file);
      this.$refs.fileInput.files = container.files;
      this.$set(this.fastUpload.item, 'loading', true);

      try {
        let formData = new FormData();
        formData.append('file', this.$refs.fileInput.files[0]);
        let response = await api.festival.members.avatar(this.$route.params.id, this.fastUpload.item.id, formData);
        this.table.list.find(x => x.id === response.data.id).photo = response.data.photo;
      } catch (e) {
        console.error(e);
      }
    },
    searchMembers: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
  },
}
</script>

<style scoped>

</style>
