import { render, staticRenderFns } from "./festival-settings-form-component.vue?vue&type=template&id=15a31e50&scoped=true&"
import script from "./festival-settings-form-component.vue?vue&type=script&lang=js&"
export * from "./festival-settings-form-component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.5_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_vue-template-_is3gtokgxivt5vyoahwc5re7cy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a31e50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/.pnpm/vuetify-loader@1.6.0_vue-template-compiler@2.6.12_vuetify@2.5.0_webpack@4.44.2/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VColorPicker,VDatePicker,VDialog,VDivider,VMenu,VRadio,VRadioGroup,VRow,VSelect,VSpacer,VTextField})
