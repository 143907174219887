<template>
  <div>
    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" md="4" class="mb-6 mb-md-0">
        <v-btn
            v-if="$gates.hasPermission('exercises.create')"
            color="primary"
            @click="showCreateExerciseModal"
            v-permission="'users.create'"
            :disabled="form.loading"
        >
          Добавить испытание
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
      </v-col>
      <v-col cols="12" md="4" >
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск по названию испытания"
            dense
            @keyup="search"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template  v-slot:item.visibility="{ item }">
            <v-btn icon @click="toggleVisibility(item)" :disabled="!$gates.hasPermission('exercises.edit.visibility')">
              <v-tooltip right content-class="tooltip-gto">
                <template #activator="{on, attrs}">
                  <div v-bind="attrs" v-on="on">
                    <v-icon
                        v-if="item.visibility"
                        color="primary"
                    >
                      mdi-eye-outline
                    </v-icon>
                    <v-icon
                        v-else
                        color="primary"
                    >
                      mdi-eye-off
                    </v-icon>
                  </div>
                </template>
                {{ item.visibility ? "Скрыть" : "Показать" }}
              </v-tooltip>
            </v-btn>

          </template>

          <template v-slot:item.title_ru="{ item }">
            <v-tooltip top content-class="tooltip-gto">
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 130px;" v-bind="attrs"
                     v-on="on">
                  {{ item.title_ru ? item.title_ru : title_en }}
                </div>
              </template>
              <span>{{ item.title_ru ? item.title_ru : title_en }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.description_ru="{ item }">
            <v-tooltip top content-class="tooltip-gto">
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 130px;" v-bind="attrs"
                     v-on="on">
                  {{ item.description_ru }}
                </div>
              </template>
              <span>  {{ item.description_ru }}</span>
            </v-tooltip>
          </template>


          <template  v-slot:item.video_link="{ item }">
            <v-tooltip left content-class="tooltip-gto">
              <template #activator="{on, attrs}">
                <v-btn v-if="item.video_link" v-bind="attrs" v-on="on" icon :href="item.video_link" target="_blank">
                  <v-icon>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </template>
              Открыть страницу с видео
            </v-tooltip>

          </template>

          <template v-if="$gates.hasPermission('exercises.edit')" v-slot:item.actions="{ item }">
            <div class="table-actions">
              <v-tooltip left content-class="tooltip-gto">
                <template #activator="{attrs, on}">
                  <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      v-if="canEdit(item)"
                      @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                Редактировать
              </v-tooltip>
              <v-tooltip left content-class="tooltip-gto">
                <template #activator="{attrs, on}">
                  <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="canDelete(item)"
                      @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                Удалить
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <create-edit-exercise ref="createEditExerciseModal"
                          :populate-with="selected"
                          :parameters-list="exercise.parameters"
                          :units-list="exercise.units"
                          @submit-success="submitted"></create-edit-exercise>

    <remove-item ref="remover" :populate-with="selected" :type="'exercise'"
                 @remove-item-success="removed()"></remove-item>
  </div>
</template>
<script>
import debounce from 'debounce'

import {mapState, mapGetters, mapActions} from 'vuex';
import {api} from '@/api';
import RemoveItem from '@/components/modals/remove-item';
import CreateEditExercise from '@/views/Exercise/components/create-edit-exercise';

export default {
  name: 'ExerciseIndex',
  components: {CreateEditExercise, RemoveItem},
  data() {
    return {
      form: {
        search: null,
        loading: false,
      },
      table: {
        headers: [
          {
            text: 'ID',
            align: 'start',
            value: 'id',
          },
          { text: 'Видимость', value: 'visibility', sortable: false },
          { text: 'Название', value: 'title_ru' },
          { text: 'Описание', value: 'description_ru', sortable: false },
          { text: 'П-тр расчета', value: 'parameter.title', sortable: false },
          { text: 'Ед. расчета', value: 'unit.title', sortable: false },
          { text: 'Видео', value: 'video_link', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      selected: null,
      exercise: {
        parameters: [],
        units: []
      },
      tooltip: {
        show: false,
        text: null
      }
    }
  },
  computed: {
    ...mapGetters('roles', [ 'filteredRoles']),
    ...mapState('roles', [ 'roles']),
    ...mapState('auth', ['user']),
    rolesList(){
      return this.filteredRoles(this)
    }
  },
  methods: {
    ...mapActions('app', ['notify']),
    showCreateExerciseModal(){
      this.selected = {};
      this.openModal();
    },
    async fetch(){
      this.form.loading = true;
      try {
        let response = await api.exercise.all({
          search: this.form.search,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.table.list = response.data.list
        this.exercise.parameters = response.data.parameters;
        this.exercise.units = response.data.units;
        this.table.meta = response.data.meta
        this.form.loading = false;
      } catch (e) {
        console.error(e);
      }
    },
    editItem(item) {
      this.selected = item;
      this.openModal();
    },
    canDelete(item) {
      return !item.has_festival_exercises;
    },
    deleteItem(item) {
      this.selected = item;
      this.$refs.remover.dialog = true;
    },
    openModal() {
      this.$refs.createEditExerciseModal.dialog = true;
    },
    canEdit() {
      return true
    },
    submitted() {
      this.fetch();
    },
    optionsUpdated() {
      this.fetch()
    },
    changeRole() {
      this.table.options.page = 1;
      this.fetch();
    },
    removed() {
      this.notify('Испытание удалено');
      this.fetch();
    },
    async toggleVisibility(item) {
      item.visibility = !item.visibility;
      try {
        await api.exercise.changeVisibility(item.id);
        this.notify('Видимость была изменена')
      } catch (e) {
        console.error(e);
      }
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500)
  },
}
</script>

<style>
.tooltip-gto {
  background: #fff;
  color: #000000;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;
  max-width: 400px;
  opacity: 1 !important;
}
</style>