<template>
  <div class="pa-4">
    <v-row>
      <v-col sm="12" lg="4" md="6">
        <v-btn
          color="primary"
          link
          :href="link"
        >
          Перейти в мобильное приложение
        </v-btn>
      </v-col>
      <v-col sm="12" lg="4" md="6">
        <v-btn
          color="primary"
          link
          :href="apk"
          download
        >
          Скачать мобильное приложение (android)
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Mobile',
  computed: {
    apk: () => {
      return process.env.VUE_APP_APK_DOWNLOAD_URL;
    },
    link: () => {
      return process.env.VUE_APP_MOBILE_APP_URL;
    }
  },
};
</script>

<style scoped></style>
